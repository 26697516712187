import { useField } from "formik";
import { TextFieldProps } from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

interface Props extends Omit<TextFieldProps, "name"> {
    name: string;
}

const PhoneNumberInput = ({
    type,
    name,
    label,
    helperText,
    ...rest
}: Props) => {
    const [field, meta, helpers] = useField(name);

    return (
        <MuiTelInput
            {...rest}
            key={name}
            label={label}
            value={field.value}
            variant="standard"
            defaultCountry="US"
            onlyCountries={["US"]}
            forceCallingCode
            disableDropdown
            disableFormatting
            error={meta.error ? true : false}
            helperText={meta.error ? meta.error : helperText}
            onChange={(value) => {
                if (!matchIsValidTel(value)) {
                    helpers.setError("Please enter a valid phone number");
                }
                helpers.setValue(value);
            }}
        />
    );
};

export default PhoneNumberInput;
