import { ReactNode } from "react";
import { useAppSelector } from "src/hooks";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { Navigate, useLocation } from "react-router-dom";

interface Props {
    children: ReactNode;
}

const AuthGuard = ({ children }: Props) => {
    const location = useLocation();
    const auth = useAppSelector(authSelector);

    if (!auth.token) {
        return (
            <Navigate
                to="/login"
                state={{
                    from: location.pathname, // so we can redirect back after successful login
                }}
            />
        );
    }

    return children;
};

export default AuthGuard;
