import { useState, useMemo } from "react";
import {
    Container,
    Typography,
    Stack,
    FormControl,
    FormLabel,
    Card,
    Box,
    Button,
    ButtonGroup,
} from "@mui/material";
import {
    AccountCircleOutlined,
    KeyOutlined,
    PersonAdd,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector, useAppDispatch, useSelectedClient } from "src/hooks";
import { authSelector, authActions } from "../auth/redux/authSlice";
import { Formik, Form } from "formik";
import {
    FormInput,
    FormDatePicker,
    FormCheckbox,
    GoogleMap,
    PhoneNumberInput,
} from "src/components";
import { capitalize } from "lodash";
import { initProfile, dataFields, accessibleFields } from "./helpers";
import { useTitle } from "react-use";
import { authAPI } from "../auth/redux/authSlice";
import { enqueueSnackbar } from "notistack";
import { getProfileSchema } from "./schema";
import moment from "moment";
import ChangePasswordModal from "./components/ChangePasswordModal";
import { useParams, useNavigate } from "react-router-dom";
import { pick, omit } from "lodash";
import type { ProfileFormData } from "./types";

interface Props {
    title?: string;
    returnTo?: string;
}

const Profile = ({ title = "Account", returnTo = "/trips" }: Props) => {
    useTitle(title);
    const { id } = useParams(); // managers can view the profile of a rider
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);

    const { config, availableFundingSources, isManager, programs } =
        useAppSelector(authSelector);
    const [updateProfile, { isLoading }] = authAPI.useUpdateProfileMutation();

    const client = useSelectedClient(id);
    const newRider = isManager && !id;
    const profileSchema = getProfileSchema(newRider);

    const initialValues = useMemo(() => {
        const values = initProfile(client);
        if (newRider) {
            return {
                ...values,
                defaultProgramName: availableFundingSources[0],
            };
        }
        return values;
    }, [client, availableFundingSources, newRider]);

    async function handleUpdate(values: ProfileFormData) {
        // only include need properties
        const data = pick(values, dataFields);
        const accessibility = pick(values.accessibility, accessibleFields);
        const request = {
            ...data,
            accessibility,
            name: `${values.firstName} ${values.lastName}`,
            birthdateEpoch: moment(values.birthdate).unix(),
        };

        try {
            const profile = await updateProfile(request).unwrap();
            // update client profile in state only if not manager
            if (!isManager) {
                dispatch(
                    authActions.save({
                        client: profile,
                    })
                );
            }

            enqueueSnackbar("Profile updated successfully", {
                variant: "success",
            });
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured. Please try again", {
                variant: "error",
            });
        }
    }

    async function handleRider(values: ProfileFormData) {
        // get full program details

        const program = programs?.find(
            (program) => program.programName === values.defaultProgramName
        );

        const request = {
            ...omit(values, ["firstName", "lastName", "birthdate"]),
            id: "new", // creates client record only
            name: `${values.firstName} ${values.lastName}`,
            birthdateEpoch: moment(values.birthdate).unix(),
            home: {
                ...values.home,
                lat: 0,
                lng: 0,
            },
            programs: [
                {
                    databaseID: program?.databaseID,
                    programName: program?.programName,
                    membershipId: values.defaultProgramMembershipID,
                },
            ],
        };

        try {
            await updateProfile(request).unwrap();
            enqueueSnackbar("Rider created successfully", {
                variant: "success",
            });

            navigate(returnTo);
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured. Please try again", {
                variant: "error",
            });
        }
    }

    return (
        <Container maxWidth="lg" sx={{ p: 1.5 }}>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                my={4}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    {newRider ? (
                        <PersonAdd fontSize="large" />
                    ) : (
                        <AccountCircleOutlined fontSize="large" />
                    )}
                    <Typography variant="h4" component="h1">
                        {title}
                    </Typography>
                </Stack>

                {/* Only users can change their passwords, managers may view and update accounts*/}
                {!isManager && (
                    <Button
                        startIcon={<KeyOutlined />}
                        onClick={() => setOpen(true)}
                    >
                        Change password
                    </Button>
                )}
            </Stack>

            <Formik
                initialValues={initialValues}
                validationSchema={profileSchema}
                onSubmit={newRider ? handleRider : handleUpdate}
            >
                {({ values }) => (
                    <Form>
                        <Stack spacing={4}>
                            {/* Agency Profile */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        {capitalize(config?.AgencyName)} profile
                                    </FormLabel>

                                    <Stack
                                        spacing={2}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <FormInput
                                            name="firstName"
                                            label="First Name"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            name="lastName"
                                            label="Last Name"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>

                                    <Stack
                                        spacing={2}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <PhoneNumberInput
                                            type="tel"
                                            name="phone"
                                            label="Phone"
                                            sx={{ flexGrow: 1 }}
                                            autoComplete="tel"
                                        />

                                        <FormDatePicker
                                            format="MM/dd/yyyy"
                                            name="birthdate"
                                            label="Birthday"
                                            sx={{ flexGrow: 1 }}
                                        />

                                        <FormInput
                                            type="email"
                                            name="email"
                                            label="Email"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Accessibility */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Accessibility
                                    </FormLabel>

                                    <Stack
                                        spacing={1}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <FormCheckbox
                                            name="accessibility.hasPCA"
                                            label="I have a personal care attendant"
                                            sx={{ flexGrow: 1 }}
                                        />

                                        <FormCheckbox
                                            name="accessibility.usesWheelchair"
                                            label="I would like an ADA accessible vehicle"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            name="accessibility.wheelChairType"
                                            label="Wheelchair Type"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Connect Rider */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Connect Rider
                                    </FormLabel>

                                    <Stack
                                        spacing={1}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        {!newRider && (
                                            <FormInput
                                                name="id"
                                                label="Passio Client ID"
                                                sx={{ flexGrow: 1 }}
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        )}

                                        <FormInput
                                            select
                                            name="defaultProgramName"
                                            label="Default Program"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            sx={{ flexGrow: 1 }}
                                        >
                                            {availableFundingSources.map(
                                                (program) => (
                                                    <option
                                                        key={program}
                                                        value={program}
                                                    >
                                                        {program}
                                                    </option>
                                                )
                                            )}
                                        </FormInput>

                                        <FormInput
                                            name="defaultProgramMembershipID"
                                            label="Membership ID"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Home Address */}
                            {isManager && (
                                <Card variant="elevation" sx={{ p: 1.5 }}>
                                    <FormControl
                                        component="fieldset"
                                        sx={{ mb: 4, display: "flex", gap: 2 }}
                                    >
                                        <FormLabel
                                            component="legend"
                                            sx={{
                                                mb: 2,
                                                fontSize: 20,
                                                color: "primary.main",
                                            }}
                                        >
                                            Home Address
                                        </FormLabel>

                                        <Stack
                                            spacing={1}
                                            direction={{
                                                xs: "column",
                                                md: "row",
                                            }}
                                        >
                                            <FormInput
                                                name="home.address1"
                                                label="Address"
                                                sx={{ flexGrow: 1 }}
                                            />
                                            <FormInput
                                                name="home.address2"
                                                label="Apt, Unit, Suite, etc"
                                                sx={{ flexGrow: 1 }}
                                            />
                                        </Stack>

                                        <Stack
                                            spacing={1}
                                            direction={{
                                                xs: "column",
                                                md: "row",
                                            }}
                                        >
                                            <FormInput
                                                name="home.city"
                                                label="City"
                                                sx={{ flexGrow: 1 }}
                                            />
                                            <FormInput
                                                name="home.state"
                                                label="State"
                                                sx={{ flexGrow: 1 }}
                                            />

                                            <FormInput
                                                name="home.zip"
                                                label="Zip"
                                                sx={{ flexGrow: 1 }}
                                            />
                                        </Stack>

                                        {/* only show map if coords are available */}
                                        {!isManager &&
                                            values?.home &&
                                            values.home?.lat !== 0 &&
                                            values.home?.lng !== 0 && (
                                                <GoogleMap
                                                    pickUpCoords={{
                                                        lat:
                                                            values.home.lat ||
                                                            0,
                                                        lng:
                                                            values.home.lng ||
                                                            0,
                                                    }}
                                                    sx={{
                                                        height: 450,
                                                        width: "100%",
                                                        my: 2,
                                                    }}
                                                />
                                            )}
                                    </FormControl>
                                </Card>
                            )}
                        </Stack>

                        {/* Actions */}
                        <Box maxWidth="sm" mx="auto" my={4}>
                            <ButtonGroup
                                fullWidth
                                variant="contained"
                                size="large"
                            >
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    sx={{ py: 1.25 }}
                                    loading={isLoading}
                                >
                                    Update
                                </LoadingButton>

                                {id && isManager && (
                                    <Button
                                        color="secondary"
                                        onClick={() => navigate(-1)}
                                    >
                                        Close
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Box>
                    </Form>
                )}
            </Formik>

            {/* change password modal */}
            <ChangePasswordModal open={open} onClose={() => setOpen(false)} />
        </Container>
    );
};

export default Profile;
