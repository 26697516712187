import { Fragment, useState } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
    ButtonGroup,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
    Delete,
    VisibilityOutlined,
    CancelOutlined,
    EventRepeat,
} from "@mui/icons-material";
import type { TripHistory } from "../../TripRequest/types";
import { Link, useNavigate } from "react-router-dom";
import { tripsAPI } from "src/modules/trips/redux/tripSlice";
import { enqueueSnackbar } from "notistack";
import { useAppSelector } from "src/hooks";
import { userSelector } from "src/modules/auth/redux/authSlice";
import { Formik, Form, FormikValues } from "formik";
import { FormDatePicker } from "src/components";
import { getGeolocation } from "src/utils/helpers";
import { formatTripLabel, formatTripInfo } from "../helpers";
import moment from "moment";

interface Props extends GridRenderCellParams<TripHistory> {
    refreshTable?: () => void;
}

const TableActions = ({ row, refreshTable }: Props) => {
    const navigate = useNavigate();
    const user = useAppSelector(userSelector);
    const isManager = user.UserType === 1;

    const [open, setOpen] = useState(false);

    const [cancelTripOfReservation, { isLoading: cancelingTripOfReservation }] =
        tripsAPI.useCancelTripOfReservationMutation();
    const [cancelReservation, { isLoading: cancelingReservation }] =
        tripsAPI.useCancelReservationMutation();
    const [cancelTrip, { isLoading: cancelingTrip }] =
        tripsAPI.useCancelTripMutation();
    const [removeTripRequest, { isLoading: cancelingTripRequest }] =
        tripsAPI.useRemoveTripRequestMutation();

    async function handleCancelTripOfReservation({
        dateToCancel,
    }: FormikValues) {
        try {
            const reason = isManager
                ? "Cancelled%20by%20Mobility%20Manager"
                : "Cancelled%20by%20Rider";

            const request = {
                cancellationReason: reason,
                reservationId: row.databaseId,
                dateToCancel: moment(dateToCancel).local().unix().toString(),
            };

            await cancelTripOfReservation(request).unwrap();

            enqueueSnackbar(
                `Your reservation for ${moment(dateToCancel).format(
                    "dddd MMM DD YYYY"
                )} has been cancelled`,
                {
                    variant: "success",
                }
            );
            setOpen(false);
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured please try again", {
                variant: "error",
            });
        }
    }

    async function handleCancelReservation() {
        try {
            const reason = isManager
                ? "Cancelled by Mobility Manager"
                : "Cancelled by Rider";

            const request = {
                cancellationReason: reason,
                reservationId: row.databaseId,
            };

            await cancelReservation(request).unwrap();

            enqueueSnackbar("Your reservation has been cancelled", {
                variant: "success",
            });

            // refresh table
            refreshTable?.();
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured please try again", {
                variant: "error",
            });
        }
    }

    async function handleCancelTrip() {
        try {
            const reason = isManager
                ? "Cancelled by Mobility Manager"
                : "Cancelled by Rider";

            let request = {
                reason,
                id: row.databaseId,
                lat: 34.85454,
                lng: 82.397747,
            };

            // try to get current location if not proceed with defaults
            try {
                const geo = await getGeolocation();
                request.lat = geo.coords.latitude;
                request.lng = geo.coords.longitude;
            } catch (error) {
                enqueueSnackbar(
                    "we couldn't get your current position. Defaults have been used instead",
                    {
                        variant: "info",
                    }
                );
            }

            await cancelTrip(request).unwrap();

            enqueueSnackbar("Your reservation has been cancelled", {
                variant: "success",
            });

            // refresh table
            refreshTable?.();
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured please try again", {
                variant: "error",
            });
        }
    }

    async function handleRemoveTripRequest() {
        try {
            const reason = isManager
                ? "Cancelled%20by%20Mobility%20Manager"
                : "Cancelled%20by%20Rider";

            const request = {
                reason: reason,
                id: row.databaseId,
            };

            await removeTripRequest(request).unwrap();

            enqueueSnackbar("Your Trip request has been cancelled", {
                variant: "success",
            });

            // refresh table
            refreshTable?.();
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured please try again", {
                variant: "error",
            });
        }
    }

    async function handleDuplication() {
        const trip = await formatTripInfo(row);
        navigate("/trips/request", {
            state: {
                duplicate: trip,
            },
        });
    }

    // permission checks
    const isPast = moment(row.tripDate).isBefore(new Date());
    const canRemoveReservation =
        row.reservationType === "R" &&
        row.secondLine !== "Recurring reservation has ended";

    const canCancelTripRequest =
        row.reservationType === "Q" && isPast && row.secondLine === "Requested";
    const canCancelTrip = row.reservationType === "T" && isPast;

    return (
        <Fragment>
            <ButtonGroup>
                <Tooltip title="View details">
                    <IconButton
                        color="primary"
                        component={Link}
                        to={`/trips/${row.databaseId}?status=history${
                            isManager ? `&rider=${row.client.id}` : ""
                        }`}
                        aria-label={formatTripLabel("See details", row)}
                    >
                        <VisibilityOutlined />
                    </IconButton>
                </Tooltip>

                {row?.pickUpPlace && row?.dropOffPlace && (
                    <Tooltip title="Duplicate Trip Request">
                        <IconButton
                            onClick={handleDuplication}
                            aria-label={formatTripLabel(
                                "duplicate trip request",
                                row
                            )}
                        >
                            <EventRepeat />
                        </IconButton>
                    </Tooltip>
                )}

                {canCancelTrip && (
                    <Tooltip title="Cancel Trip">
                        <LoadingButton
                            color="warning"
                            onClick={handleCancelTrip}
                            disabled={cancelingTrip}
                            loading={cancelingTrip}
                            aria-label={formatTripLabel(
                                `${
                                    cancelingTrip ? "Canceling" : "Cancel"
                                } trip`,
                                row
                            )}
                        >
                            <CancelOutlined />
                        </LoadingButton>
                    </Tooltip>
                )}

                {canRemoveReservation && (
                    <Tooltip title="Cancel single trip">
                        <IconButton
                            color="warning"
                            onClick={() => setOpen(true)}
                            aria-label={formatTripLabel(
                                "Cancel single trip",
                                row
                            )}
                        >
                            <CancelOutlined />
                        </IconButton>
                    </Tooltip>
                )}

                {canRemoveReservation && (
                    <Tooltip title="Remove reservation">
                        <LoadingButton
                            color="error"
                            onClick={handleCancelReservation}
                            disabled={cancelingReservation}
                            loading={cancelingReservation}
                            aria-label={formatTripLabel(
                                `${
                                    cancelingReservation
                                        ? "Cancelling"
                                        : "Cancel"
                                } reservation`,
                                row
                            )}
                        >
                            <Delete />
                        </LoadingButton>
                    </Tooltip>
                )}

                {canCancelTripRequest && (
                    <Tooltip title="Remove Trip Request">
                        <LoadingButton
                            color="error"
                            onClick={handleRemoveTripRequest}
                            disabled={cancelingTripRequest}
                            loading={cancelingTripRequest}
                            aria-label={formatTripLabel(
                                `${
                                    cancelingTripRequest ? "Removing" : "Remove"
                                } trip request`,
                                row
                            )}
                        >
                            <Delete />
                        </LoadingButton>
                    </Tooltip>
                )}
            </ButtonGroup>

            <Dialog open={open} maxWidth="md" onClose={() => setOpen(false)}>
                <Formik
                    initialValues={{
                        dateToCancel: moment(row.nextTripDate).toDate(),
                    }}
                    onSubmit={handleCancelTripOfReservation}
                >
                    <Form>
                        <DialogTitle>Cancel Single Trip</DialogTitle>
                        <DialogContent>
                            <DialogContentText gutterBottom>
                                Please enter the date you want to cancel. Your
                                next trip is scheduled for:
                            </DialogContentText>

                            <DialogContentText color="primary.main">
                                {moment(row.nextTripDate).format("LLLL")}
                            </DialogContentText>
                            <FormDatePicker
                                format="MM/dd/yyyy"
                                name="dateToCancel"
                                label="Trip Date"
                                sx={{ width: "100%", my: 2 }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <LoadingButton
                                color="warning"
                                type="submit"
                                loading={cancelingTripOfReservation}
                            >
                                Continue
                            </LoadingButton>
                        </DialogActions>
                    </Form>
                </Formik>
            </Dialog>
        </Fragment>
    );
};

export default TableActions;
