import {
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { Stack, Typography } from "@mui/material";
import type { TripHistory } from "../TripRequest/types";
import moment from "moment";
import TableActions from "./components/TableActions";
import type { TripReservationType } from "../../types";
import TripCard from "./components/TripCard";

/// create columns factory which accepts a function to refresh the table
interface CreateColumnArgs {
    refreshTable: () => void;
}

export function formatReservationName(type: TripReservationType) {
    switch (type) {
        case "T":
            return "Trip";
        case "Q":
            return "Request";
        case "R":
            return "Reservation";
        default:
            return "N/A";
    }
}

/**
 * Column factory
 * @see https://mui.com/x/react-data-grid/column-definition/
 */

export function createColumns({
    refreshTable,
}: CreateColumnArgs): GridColDef[] {
    return [
        {
            field: "tripDate",
            headerName: "Trip Date",
            flex: 2,
            description: "Date of the trip",
            // this value getter enables the column to be filterable by both the date and the second line
            valueGetter: ({ row }: GridValueGetterParams<TripHistory>) => {
                return `${moment(row.tripDate).format("LLLL")} ${
                    row.secondLine
                }`;
            },
            renderCell: ({ row }: GridRenderCellParams<TripHistory>) => {
                return (
                    <Stack spacing={1} py={1.5}>
                        <Typography variant="body2" color="text.secondary">
                            {row.tripDate
                                ? moment(row.tripDate).format("LLLL")
                                : "N/A"}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {row.secondLine}
                        </Typography>
                    </Stack>
                );
            },
            minWidth: 290,
        },
        {
            field: "puPlace",
            headerName: "Pick Up Place",
            flex: 1,
            description: "Place where the passenger was picked up",
            valueGetter: ({ row }: GridValueGetterParams<TripHistory>) => {
                return `${row.pickUpPlace?.name || "N/A"}`;
            },
            minWidth: 290,
        },
        {
            field: "doPlace",
            headerName: "Drop Off Place",
            flex: 1,
            description: "Place where the passenger was dropped off",
            valueGetter: ({ row }: GridValueGetterParams<TripHistory>) => {
                return `${row.dropOffPlace?.name || "N/A"}`;
            },
            minWidth: 290,
        },
        {
            field: "reservationType",
            headerName: "Type",
            flex: 1,
            description: "Trip type",
            valueFormatter: ({
                value,
            }: GridValueFormatterParams<TripHistory["reservationType"]>) => {
                return formatReservationName(value);
            },
            minWidth: 290,
        },
        {
            field: "dummy",
            headerName: "Actions",
            flex: 1,
            description: "Actions your can perfom on this trip",
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params: GridRenderCellParams<TripHistory>) => {
                return <TableActions {...params} refreshTable={refreshTable} />;
            },
            minWidth: 290,
        },
        {
            field: "mobileCard",
            headerName: "All Trips",
            flex: 1,
            description: "Full trip details",
            disableColumnMenu: true,
            // this value getter enables the column to be filterable by both the date and the second line
            valueGetter: ({ row }: GridValueGetterParams<TripHistory>) => {
                return `
                ${moment(row.tripDate).format("LLLL")} 
                ${row.secondLine} 
                ${row.pickUpPlace?.name}
                ${row.dropOffPlace?.name}
                ${formatReservationName(row.reservationType)}
                `;
            },
            renderCell: (params: GridRenderCellParams<TripHistory>) => {
                return <TripCard {...params} refreshTable={refreshTable} />;
            },
            minWidth: 290,
        },
    ];
}

export function formatTripLabel(prefix: string, trip: TripHistory) {
    const date = moment(trip.tripDate).format("LLLL");
    return `${prefix} for ${date} from ${trip.pickUpPlace?.name} to ${trip.dropOffPlace?.name}`;
}

export function formatTripInfo(trip: TripHistory) {
    if (!trip || !trip.pickUpPlace || !trip.dropOffPlace) return {};

    const { pickUpPlace, dropOffPlace } = trip;

    return {
        pickUpAddressFull: `${pickUpPlace.name} ${pickUpPlace.address1}`,
        pickUpPlaceName: pickUpPlace.name ?? "",
        pickUpAddress: pickUpPlace.address1 ?? "",
        pickUpAddress2: pickUpPlace.address2 ?? "",
        pickUpCity: pickUpPlace.city ?? "",
        pickUpState: pickUpPlace.state ?? "",
        pickUpZipCode: pickUpPlace.zip ?? "",
        pickUpLatitude: pickUpPlace.lat ?? 0,
        pickUpLongitude: pickUpPlace.lng ?? 0,
        dropOffAddressFull: `${dropOffPlace.name} ${dropOffPlace.address1}`,
        dropOffPlaceName: dropOffPlace.name ?? "",
        dropOffAddress: dropOffPlace.address1 ?? "",
        dropOffAddress2: dropOffPlace.address2 ?? "",
        dropOffCity: dropOffPlace.city ?? "",
        dropOffState: dropOffPlace.state ?? "",
        dropOffZipCode: dropOffPlace.zip ?? "",
        dropOffLatitude: dropOffPlace.lat ?? 0,
        dropOffLongitude: dropOffPlace.lng ?? 0,
    };
}
