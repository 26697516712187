import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import LoginModal from "src/modules/auth/components/LoginModal";

const DashboardLayout = () => {
    return (
        <Fragment>
            <Navbar />
            <Outlet />
            <LoginModal />
        </Fragment>
    );
};

export default DashboardLayout;
