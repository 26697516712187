import {
    Box,
    Alert,
    Dialog,
    Button,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { userSelector } from "src/modules/auth/redux/authSlice";
import { useAppSelector, useAppDispatch } from "src/hooks";
import { FormInput } from "src/components";
import { Formik, Form } from "formik";
import { passwordSchema } from "../schema";
import { authAPI, logout } from "src/modules/auth/redux/authSlice";
import { enqueueSnackbar } from "notistack";

interface Props extends Omit<DialogProps, "onClose"> {
    onClose: () => void;
}
interface FormValues {
    oldPassword: string;
    newPassword: string;
    confirmPassword?: string;
}

const ChangePasswordModal = ({ open, onClose, ...rest }: Props) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(userSelector);

    const [changePassword, { isLoading }] = authAPI.useChangePasswordMutation();

    async function handleSubmit(values: FormValues) {
        try {
            delete values.confirmPassword;
            const request = {
                ...values,
                userId: user?.UserId,
            };

            await changePassword(request).unwrap();
            enqueueSnackbar("Password changed successfully. Please login", {
                variant: "success",
            });

            dispatch(logout());
            onClose();
        } catch (error) {
            enqueueSnackbar("Sorry an error occured", {
                variant: "error",
            });
        }
    }

    return (
        <Dialog open={open} onClose={onClose} {...rest}>
            <Formik
                initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                }}
                validationSchema={passwordSchema}
                onSubmit={handleSubmit}
            >
                <Form>
                    <DialogTitle>Change Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Alert severity="warning">
                                You will have to login again after changing your
                                password
                            </Alert>
                        </DialogContentText>

                        <Box component={Form} sx={{ mt: 1 }}>
                            <FormInput
                                fullWidth
                                name="oldPassword"
                                label="Current Password"
                                type="password"
                                margin="normal"
                            />
                            <FormInput
                                fullWidth
                                name="newPassword"
                                label="New Password"
                                type="password"
                                margin="normal"
                            />
                            <FormInput
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                margin="normal"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <LoadingButton type="submit" loading={isLoading}>
                            Continue
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
};

export default ChangePasswordModal;
