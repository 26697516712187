// shared typing for the whole system

export enum PassioService {
    paraplan = "paraplan",
    navigator = "navigator",
}

export enum TimeTypes {
    pickedUp = "Picked Up",
    droppedOff = "Dropped Off",
}

// extensible response type
export interface ListResponse<T> {
    errorMessage: string;
    list: Array<T>;
    success: boolean;
    tokenExists: boolean;
    tokenIsValid: boolean;
}
