import {
    Card,
    Stack,
    CardActions,
    CardContent,
    Typography,
    Divider,
    Chip,
} from "@mui/material";
import { TripHistory } from "../../TripRequest/types";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { formatReservationName } from "../helpers";
import TableActions from "./TableActions";
import moment from "moment";

interface Props extends GridRenderCellParams<TripHistory> {
    refreshTable?: () => void;
}

type StatusTypes = "Scheduled" | "Accepted" | "Rejected" | "Requested";

function getStatusColor(status: StatusTypes | string) {
    switch (status) {
        case "Scheduled":
            return "success";
        case "Accepted":
            return "secondary";
        case "Rejected":
            return "error";
        case "Requested":
            return "info";
        default:
            return "default";
    }
}

const TripCard = ({ row, refreshTable, ...rest }: Props) => {
    const isScheduled = row?.secondLine.includes("Scheduled");

    const color = getStatusColor(isScheduled ? "Scheduled" : row.secondLine);

    return (
        <Card
            sx={{
                my: 2,
                borderRadius: 2,
                width: "100%",
            }}
            elevation={5}
        >
            <CardContent sx={{ p: 2 }}>
                <Stack
                    spacing={4}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                    mb={2}
                >
                    <Typography variant="h6" fontWeight="medium">
                        {formatReservationName(row.reservationType) || "N/A"}
                    </Typography>
                    <Chip
                        color={color}
                        label={
                            isScheduled ? "Scheduled" : row.secondLine || "N/A"
                        }
                    />
                </Stack>

                <Typography
                    variant="subtitle2"
                    gutterBottom
                    fontWeight="medium"
                >
                    Trip Date
                </Typography>
                <Typography variant="body2" gutterBottom mb={2}>
                    {row.tripDate ? moment(row.tripDate).format("LLLL") : "N/A"}
                </Typography>

                <Typography
                    variant="subtitle2"
                    gutterBottom
                    fontWeight="medium"
                >
                    Pick Up Place
                </Typography>
                <Typography variant="body2" gutterBottom mb={2}>
                    {row.pickUpPlace?.name || "N/A"}
                </Typography>

                <Typography
                    variant="subtitle2"
                    gutterBottom
                    fontWeight="medium"
                >
                    Drop Off Place
                </Typography>
                <Typography variant="body2" gutterBottom mb={2}>
                    {row.dropOffPlace?.name || "N/A"}
                </Typography>

                {isScheduled &&
                    row.secondLine
                        .trim()
                        .split("\r\n")
                        .map((item, index) => (
                            <Typography
                                variant="body2"
                                gutterBottom
                                mb={2}
                                key={index}
                            >
                                {item}
                            </Typography>
                        ))}
            </CardContent>
            <Divider />
            <CardActions>
                <TableActions {...rest} row={row} refreshTable={refreshTable} />
            </CardActions>
        </Card>
    );
};

export default TripCard;
