import { useState } from "react";
import {
    Tooltip,
    IconButton,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import {
    useGridApiContext,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    GridSlotsComponentsProps,
} from "@mui/x-data-grid";
import { Refresh } from "@mui/icons-material";
import { TripReservationType } from "src/modules/trips/types";

interface Props extends NonNullable<GridSlotsComponentsProps["toolbar"]> {
    label?: string;
    refreshTable?: () => void;
}
const CustomToolbar = ({ label, refreshTable }: Props) => {
    const gridApiRef = useGridApiContext();
    const [filter, setFilter] = useState<TripReservationType | string>("");

    function handleFilter(type: TripReservationType) {
        setFilter(type);
        gridApiRef?.current?.setFilterModel({
            items: [
                { field: "reservationType", operator: "contains", value: type },
            ],
        });
    }
    return (
        <GridToolbarContainer sx={{ p: 1, gap: 2 }}>
            {label && (
                <Typography
                    variant="h6"
                    component="h2"
                    color="primary.main"
                    flexGrow={1}
                >
                    {label}
                </Typography>
            )}
            <GridToolbarQuickFilter sx={{ flexGrow: 1 }} />

            <ToggleButtonGroup
                color="primary"
                value={filter}
                exclusive
                onChange={(_, value) => handleFilter(value)}
                aria-label="Filter by reservation type"
                size="small"
            >
                <ToggleButton value="T">Trips</ToggleButton>
                <ToggleButton value="Q">Requests</ToggleButton>
            </ToggleButtonGroup>

            <Tooltip title="refresh Table">
                <IconButton size="large" onClick={() => refreshTable?.()}>
                    <Refresh />
                </IconButton>
            </Tooltip>
        </GridToolbarContainer>
    );
};

export default CustomToolbar;
