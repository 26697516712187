import {
    configureStore,
    combineReducers,
    PayloadAction,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import API from "./api";
import authReducer, { logout } from "src/modules/auth/redux/authSlice";
import tripReducer from "src/modules/trips/redux/tripSlice";
import { TokenExpiryCheckerMiddleware } from "src/utils/middleware";

const persistConfig = {
    key: "passio-connect-rider",
    blacklist: ["api"],
    storage,
};

const appReducers = combineReducers({
    auth: authReducer,
    trips: tripReducer,
    [API.reducerPath]: API.reducer,
});

export const rootReducer = (state: any, action: PayloadAction) => {
    if (logout.match(action)) {
        state = undefined;
    }
    return appReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat([API.middleware, TokenExpiryCheckerMiddleware]),
    devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
