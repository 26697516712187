import { authSelector } from "src/modules/auth/redux/authSlice";
import { useAppSelector } from "./typedHooks";
import { Client } from "src/modules/auth/types";
/**
 * This hook helps normalize the client. If a manager is requesting on behalf of a client, we return
 * the client that was selected. Otherwise, we return the client that is logged in.
 * @param id - the id of the client to get
 * @returns the selected client
 */

export const useSelectedClient = (id: string | undefined): Client => {
    const parsedId = id && parseInt(id);
    const { isManager, clients, client } = useAppSelector(authSelector);

    if (parsedId && isManager) {
        const rider = clients?.find((rider) => rider.id === parsedId);
        return rider ?? client;
    }

    return client;
};

export default useSelectedClient;
