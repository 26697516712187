import ListItemText, { ListItemTextProps } from "@mui/material/ListItemText";
// custom list item with styles applied
const CustomListItemText = (props: ListItemTextProps) => {
    const primaryProps = {
        sx: {
            fontSize: { xs: "inherit", md: 18 },
            fontWeight: 500,
        },
    };

    const secondaryProps = {
        sx: {
            fontSize: { xs: "inherit", md: 18 },
        },
    };
    return (
        <ListItemText
            primaryTypographyProps={primaryProps}
            secondaryTypographyProps={secondaryProps}
            {...props}
        />
    );
};

export default CustomListItemText;
