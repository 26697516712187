import moment from "moment";
import type { Client } from "src/modules/auth/types";
import { ProfileFormData } from "./types";

export function initProfile(client: Client): ProfileFormData {
    if (client && Object.keys(client).length) {
        const [firstName, ...lastName] = client?.name?.split(" ");
        return {
            ...client,
            firstName,
            lastName: lastName.join(" "),
            birthdate: moment(client?.birthdate).toDate(),
        };
    }

    return {
        firstName: "",
        lastName: "",
    };
}

// selected fields for profile update
export const accessibleFields = ["hasPCA", "usesWheelchair", "wheelChairType"];
export const dataFields = [
    "id",
    "email",
    "defaultProgramMembershipID",
    "defaultProgramName",
];
