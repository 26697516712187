import { Tooltip, IconButton, Typography } from "@mui/material";
import {
    GridToolbarContainer,
    GridToolbarQuickFilter,
    GridSlotsComponentsProps,
} from "@mui/x-data-grid";
import { Refresh } from "@mui/icons-material";

interface Props extends NonNullable<GridSlotsComponentsProps["toolbar"]> {
    label?: string;
    refreshTable?: () => void;
}
const CustomToolbar = ({ label, refreshTable }: Props) => {
    return (
        <GridToolbarContainer sx={{ p: 1 }}>
            {label && (
                <Typography
                    variant="h6"
                    component="h2"
                    color="primary.main"
                    flexGrow={1}
                >
                    {label}
                </Typography>
            )}
            <GridToolbarQuickFilter sx={{ flexGrow: 1 }} />
            <Tooltip title="refresh Table">
                <IconButton size="large" onClick={() => refreshTable?.()}>
                    <Refresh />
                </IconButton>
            </Tooltip>
        </GridToolbarContainer>
    );
};

export default CustomToolbar;
