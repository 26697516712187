import { useState, useEffect, useMemo } from "react";
import {
    Box,
    Grid,
    List,
    Stack,
    Button,
    ListItem,
    Container,
    Typography,
    ButtonGroup,
} from "@mui/material";
import { FeedOutlined } from "@mui/icons-material";
import { useTitle } from "react-use";
import { useAppSelector } from "src/hooks";
import {
    GoogleMap,
    CustomListItemText,
    Error,
    Splashscreen,
} from "src/components";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { tripsAPI } from "../../redux/tripSlice";
import { device } from "src/utils/constants";
import * as helpers from "./helpers";
import { isFetchBaseQueryError } from "src/utils/helpers";

/**
 *
 * @param id - trip id, uuidv4 format
 * Optional query parameters include status and rider id if manager requested this trip
 * @examples
 * @param status - trip status, one of "live", "history"
 * @param rider - rider id number 12345
 */

const Details = () => {
    useTitle("Trip Confirmation");
    const { id = "" } = useParams(); // trip id
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const status = searchParams.get("status") ?? "";
    const rider = searchParams.get("rider"); // manager can request trip for rider
    const { isManager, token } = useAppSelector(authSelector);
    const [stop, setStop] = useState(false);

    const request = {
        device,
        tripId: id,
        token,
        v2: true,
    };

    const { data, isLoading, isError, error, refetch } =
        tripsAPI.useGetTripStatusQuery(request, {
            skip: !id || stop,
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
            pollingInterval: !stop ? 15000 : 0,
        });

    const coords = useMemo(() => data?.geo, [data?.geo]);

    function handleClose() {
        // if trip was requested by manager, redirect to riders history
        if (rider && isManager) {
            navigate(`/riders/${rider}/history`);
            return;
        }
        navigate("/trips/history");
    }

    // stop polling if trip is completed, rejected or cancelled
    useEffect(() => {
        if (data?.rideStatus && [11, 12, 13].includes(data.rideStatus)) {
            setStop(true);
        }
    }, [data?.rideStatus]);

    if (isLoading) {
        return <Splashscreen description="processing" />;
    }

    if (isError && isFetchBaseQueryError(error)) {
        return <Error description={(error.data as any)?.errorMessage} />;
    } else if (isError) {
        return (
            <Error
                description="Sorry we couldn't update status. Please check your connection and try again"
                action={
                    <Button
                        variant="contained"
                        sx={{ py: 1.5, px: 4, my: 2 }}
                        onClick={() => refetch()}
                    >
                        Try again
                    </Button>
                }
            />
        );
    }

    // trip has no coordinates and data until approved or assigned
    if (data?.rideStatus === 1 && !data?.request && !data?.geo && !isError) {
        return (
            <Error
                description={helpers.getStatusText(data?.rideStatus)}
                title={`Thanks! ${helpers.formatName(data?.request)}`}
                action={
                    <Button
                        variant="contained"
                        sx={{ py: 1.5, px: 4, my: 2 }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                }
            />
        );
    }

    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            {status === "live" ? (
                <Typography variant="h4" component="h2" my={4}>
                    Thanks! {helpers.formatName(data?.request)}
                </Typography>
            ) : (
                <Typography variant="h4" component="h1" mt={4}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <FeedOutlined fontSize="large" />
                        <span>Trip Details</span>
                    </Stack>
                </Typography>
            )}

            <Grid container spacing={2}>
                {/* Details  */}
                <Grid item xs={12} md={6}>
                    <List>
                        <ListItem disableGutters>
                            <CustomListItemText
                                primary="Status"
                                secondary={helpers.getStatusText(
                                    data?.rideStatus
                                )}
                            />
                        </ListItem>
                        <ListItem disableGutters>
                            <CustomListItemText
                                primary="Pick Up Address"
                                secondary={
                                    data?.geo?.pickUp
                                        ? helpers.formatAddress(
                                              data?.geo?.pickUp
                                          )
                                        : `${data?.request?.pickUpAddress}, ${data?.request?.pickUpCity}`
                                }
                            />
                        </ListItem>
                        <ListItem disableGutters>
                            <CustomListItemText
                                primary="Scheduled Pick Up Time"
                                secondary={
                                    data?.times?.scheduledPickUpTimeEpoch
                                        ? helpers.formatTime(
                                              data?.times
                                                  ?.scheduledPickUpTimeEpoch
                                          )
                                        : helpers.formatTime(
                                              data?.request?.pickUpTimeEpoch
                                          )
                                }
                            />
                        </ListItem>
                        {/* Arrival */}

                        {data?.times?.actualPickUpArrivalEpoch && (
                            <ListItem disableGutters>
                                <CustomListItemText
                                    primary="Arrived at"
                                    secondary={helpers.formatTime(
                                        data?.times?.actualPickUpArrivalEpoch
                                    )}
                                />
                            </ListItem>
                        )}
                        {data?.times?.estimatedPickUpArrivalEpoch && (
                            <ListItem disableGutters>
                                <CustomListItemText
                                    primary="ETA to arrival"
                                    secondary={helpers.formatTime(
                                        data?.times?.estimatedPickUpArrivalEpoch
                                    )}
                                />
                            </ListItem>
                        )}
                        <ListItem disableGutters>
                            <CustomListItemText
                                primary="Drop Off Address"
                                secondary={
                                    data?.geo?.dropOff
                                        ? helpers.formatAddress(
                                              data?.geo?.dropOff
                                          )
                                        : `${data?.request?.dropOffAddress}, ${data?.request?.dropOffCity}`
                                }
                            />
                        </ListItem>
                        <ListItem disableGutters>
                            <CustomListItemText
                                primary="Scheduled Drop Off Time"
                                secondary={
                                    data?.times?.scheduledDropOffTimeEpoch
                                        ? helpers.formatTime(
                                              data?.times
                                                  ?.scheduledDropOffTimeEpoch
                                          )
                                        : helpers.formatTime(
                                              data?.request?.dropOffTimeEpoch
                                          )
                                }
                            />
                        </ListItem>

                        {/* Peform */}
                        {data?.times?.actualDropOffTimePerformEpoch && (
                            <ListItem disableGutters>
                                <CustomListItemText
                                    primary="Completed at"
                                    secondary={helpers.formatTime(
                                        data?.times
                                            ?.actualDropOffTimePerformEpoch
                                    )}
                                />
                            </ListItem>
                        )}

                        {data?.times?.estimatedDropOffArrivalEpoch && (
                            <ListItem disableGutters>
                                <CustomListItemText
                                    primary="ETA to drop off"
                                    secondary={helpers.formatTime(
                                        data?.times
                                            ?.estimatedDropOffArrivalEpoch
                                    )}
                                />
                            </ListItem>
                        )}
                        {data?.times?.estimatedDropOffTimePerformEpoch && (
                            <ListItem disableGutters>
                                <CustomListItemText
                                    primary="ETA to completion"
                                    secondary={helpers.formatTime(
                                        data?.times
                                            ?.estimatedDropOffTimePerformEpoch
                                    )}
                                />
                            </ListItem>
                        )}
                    </List>
                </Grid>

                {/* Map preview for program geozones */}
                <Grid item xs={12} md={6}>
                    <GoogleMap
                        loading={isLoading}
                        // showDirections={showDirections}
                        pickUpCoords={{
                            lat:
                                coords?.pickUp?.lat ||
                                parseFloat(
                                    data?.request?.pickUpLatitude || "0"
                                ) ||
                                0,
                            lng:
                                coords?.pickUp?.lng ||
                                parseFloat(
                                    data?.request?.pickUpLongitude || "0"
                                ) ||
                                0,
                        }}
                        dropOffCoords={{
                            lat:
                                coords?.dropOff?.lat ||
                                parseFloat(
                                    data?.request?.dropOffLatitude || "0"
                                ) ||
                                0,
                            lng:
                                coords?.dropOff?.lng ||
                                parseFloat(
                                    data?.request?.dropOffLongitude || "0"
                                ) ||
                                0,
                        }}
                        vehicleCoords={{
                            lat: coords?.vehicle?.lat || 0,
                            lng: coords?.vehicle?.lng || 0,
                        }}
                        riderCoords={{
                            lat: coords?.rider?.lat || 0,
                            lng: coords?.rider?.lng || 0,
                        }}
                        sx={{
                            height: 450,
                            width: "100%",
                        }}
                    />
                </Grid>
            </Grid>

            <Box maxWidth="sm" my={4} mx="auto">
                <ButtonGroup
                    variant="contained"
                    size="large"
                    sx={{ my: 2 }}
                    fullWidth
                >
                    {/* only visible to riders */}

                    {!isManager && (
                        <Button
                            component={Link}
                            to="/trips/request"
                            sx={{ py: 1.5 }}
                        >
                            New Trip
                        </Button>
                    )}
                    <Button
                        color="secondary"
                        sx={{ py: 1.5 }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ButtonGroup>
            </Box>
        </Container>
    );
};

export default Details;
