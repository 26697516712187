import {
    Card,
    CardActions,
    CardContent,
    Typography,
    Divider,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ManagedClient } from "src/modules/auth/types";
import TableActions from "./TableActions";
import moment from "moment";

const ClientCard = ({ row, ...rest }: GridRenderCellParams<ManagedClient>) => {
    return (
        <Card
            sx={{
                my: 2,
                borderRadius: 2,
                width: "100%",
            }}
            elevation={5}
        >
            <CardContent sx={{ p: 2 }}>
                <Typography variant="h6" fontWeight="medium" mb={1}>
                    {row.name || "N/A"}
                </Typography>

                <Typography
                    variant="subtitle2"
                    gutterBottom
                    fontWeight="medium"
                >
                    Date Of Birth
                </Typography>
                <Typography variant="body2" gutterBottom mb={2}>
                    {row.birthdate ? moment(row.birthdate).format("L") : "N/A"}
                </Typography>

                <Typography
                    variant="subtitle2"
                    gutterBottom
                    fontWeight="medium"
                >
                    Email
                </Typography>
                <Typography variant="body2" gutterBottom mb={2}>
                    {row.email || "N/A"}
                </Typography>

                <Typography
                    variant="subtitle2"
                    gutterBottom
                    fontWeight="medium"
                >
                    Phone Number
                </Typography>
                <Typography variant="body2" gutterBottom mb={2}>
                    {row.phone || "N/A"}
                </Typography>
            </CardContent>
            <Divider />
            <CardActions>
                <TableActions row={row} {...rest} />
            </CardActions>
        </Card>
    );
};

export default ClientCard;
