import {
    useMemo,
    useState,
    memo,
    Fragment,
    useCallback,
    useRef,
    useEffect,
} from "react";
import { useFormikContext } from "formik";
import { Grid, FormLabel, FormControl } from "@mui/material";
import {
    FormInput,
    PlacePicker,
    GoogleMap,
    PlacesAutocomplete,
} from "src/components";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { useAppSelector } from "src/hooks";
import { enqueueSnackbar } from "notistack";
import { tripsAPI } from "src/modules/trips/redux/tripSlice";
import { getMapShape } from "./helpers";
import type { TripRequestFormValues } from "../types";
import type { MapGeoFence } from "src/components/map/GoogleMap";
import type { ParsedAddress } from "src/components/forms/types";
import { OUT_OF_ZONE_ERROR } from "../helpers";
import TripPrograms from "./TripPrograms";

interface ProcessingState {
    active?: boolean;
    pickUp?: boolean;
    dropOff?: boolean;
    message?: string;
}

const LocationSelector = () => {
    const auth = useAppSelector(authSelector);
    const preferences = auth?.config?.ConnectPreferences;
    const canRequestAllLocations =
        preferences?.CanRequestAnyPULocation &&
        preferences?.CanRequestAnyDOLocation;
    const clientPrograms = auth?.client?.programs;
    const { values, validateForm } = useFormikContext<TripRequestFormValues>();

    const geofenceChecked = useRef(false);
    const [getGeofence] = tripsAPI.useGetGeofenceMutation();
    const [checkGeoEligibility] =
        tripsAPI.useCheckGeographicEligibiltyMutation();

    const [geoFence, setGeoFence] = useState<MapGeoFence>({
        polygons: [],
        circles: [],
    });

    const [processing, setProcessing] = useState<ProcessingState>({
        active: false,
        pickUp: false,
        dropOff: false,
        message: "processing ...",
    });

    const pickUpSpots = useMemo(() => {
        const filtered = auth.places?.filter((item) => item.isPickUpSpot) ?? [];
        return filtered.sort((a, b) => -b.name.localeCompare(a.name));
    }, [auth.places]);

    const dropOffSpots = useMemo(() => {
        const filtered =
            auth.places?.filter((item) => item.isDropOffSpot) ?? [];
        return filtered.sort((a, b) => -b.name.localeCompare(a.name));
    }, [auth.places]);

    const getActiveZones = useCallback(
        (programName: string) => {
            const program = clientPrograms?.find(
                (program) => program.programName === programName
            );
            return program ? program?.activeGeozoneIds : [];
        },
        [clientPrograms]
    );

    async function handleGeoEligibilityCheck(
        latitude: number,
        longitude: number,
        type: keyof ProcessingState = "pickUp"
    ) {
        const activeZones = getActiveZones(values.tripProgram);

        // if we dont have active zones but a check was requested, return true
        if (!activeZones.length && latitude && longitude) return true;

        // if coords are invalid and zones are active return false
        if (activeZones.length && (!latitude || !longitude)) return false;

        try {
            setProcessing({ [type]: true });
            const request = {
                latitude,
                longitude,
                passioToken: auth.passioToken,
                passioAgency: auth.config.PassioAgency,
            };
            const response = await checkGeoEligibility(request).unwrap();
            const zonePoints = response.map((zone) => zone.id);
            const isInZone = activeZones.some((zone) =>
                zonePoints.includes(zone)
            );
            setProcessing({ [type]: false });
            return isInZone;
        } catch (error) {
            enqueueSnackbar(
                "Sorry that address is probably not in our service area",
                {
                    variant: "error",
                }
            );
            setProcessing({ [type]: false });
        }
    }

    // check eligibility if trip program has active zones
    async function validateAddress(
        lat: number,
        lng: number,
        type: keyof ProcessingState = "pickUp"
    ) {
        let error = "";
        if (!lat || !lng) return error;

        const isInZone = await handleGeoEligibilityCheck(lat, lng, type);
        if (!isInZone) {
            error = OUT_OF_ZONE_ERROR;
        }
        return error;
    }

    const handleLoadGeoFence = useCallback(
        async (programName: string) => {
            // reset map
            setGeoFence({
                polygons: [],
                circles: [],
            });

            try {
                const activeZones = getActiveZones(programName);
                if (activeZones.length) {
                    // request might be multiple, using the redux loading state won't be ideal if so
                    setProcessing({
                        active: true,
                        message: "Mapping active zones",
                    });

                    for (const zone of activeZones) {
                        const currentZone = auth?.geozones?.find(
                            (item) => item.ID === parseInt(zone)
                        );

                        // if zone is active, we load the fence
                        if (currentZone) {
                            const request = {
                                passioToken: auth.passioToken,
                                passioAgency: auth.config.PassioAgency,
                                id: currentZone.ID,
                            };

                            const response = await getGeofence(
                                request
                            ).unwrap();
                            const shape = getMapShape(response);

                            setGeoFence((current) => {
                                const key = `${response.shape.type}s`;
                                if (!current[key]) {
                                    return current;
                                }
                                return {
                                    ...current,
                                    [key]: [...current[key], shape],
                                };
                            });
                        }
                    }
                    // all done lets clear up
                    setProcessing({
                        active: false,
                        message: "Active zones mapped!",
                    });
                }
            } catch (error) {
                setProcessing({
                    active: false,
                    message: "",
                });
            }
        },
        [auth, getActiveZones, getGeofence]
    );

    async function handleTripProgram(value: string) {
        // refresh geofence then recheck addresses
        await handleLoadGeoFence(value);
        // revalidate form, time fields are dependent on trip programs
        await validateForm();
    }

    useEffect(() => {
        if (!geofenceChecked.current && values.tripProgram) {
            handleLoadGeoFence(values.tripProgram);
        }
        return () => {
            geofenceChecked.current = true;
        };
    }, [values.tripProgram, handleLoadGeoFence]);

    return (
        <Grid
            container
            item
            sx={{
                p: 2,
                mb: 4,
                boxShadow: 1,
                borderRadius: 1,
            }}
        >
            <Grid
                item
                xs={12}
                md={6}
                sx={{ pr: { md: 2 }, mb: { xs: 4, md: 0 } }}
            >
                {/* Show custom place picker if user cannot request all locations */}
                {!canRequestAllLocations ? (
                    <Fragment>
                        {/* Pickup spot */}
                        <FormControl
                            component="fieldset"
                            sx={{ mb: 4, display: "flex", gap: 2 }}
                        >
                            <FormLabel
                                component="legend"
                                sx={{
                                    mb: 2,
                                    fontSize: 20,
                                    color: "primary.main",
                                }}
                            >
                                Pick Up Spot
                            </FormLabel>

                            <PlacePicker
                                label="Address"
                                id="pickUpPlace"
                                name="pickUpPlace"
                                options={pickUpSpots}
                                sx={{ flexGrow: 1 }}
                            />

                            <FormInput
                                multiline
                                name="pickUpAddress2"
                                label="Apt/Suite/Notes"
                                sx={{ flexGrow: 1 }}
                            />
                        </FormControl>

                        {/* drop off spot */}
                        <FormControl
                            component="fieldset"
                            sx={{ mb: 4, display: "flex", gap: 2 }}
                        >
                            <FormLabel
                                component="legend"
                                sx={{
                                    mb: 2,
                                    fontSize: 20,
                                    color: "primary.main",
                                }}
                            >
                                Drop Off Spot
                            </FormLabel>

                            <PlacePicker
                                label="Address"
                                id="dropOffPlace"
                                name="dropOffPlace"
                                options={dropOffSpots}
                                sx={{ flexGrow: 1 }}
                            />

                            <FormInput
                                multiline
                                name="dropOffAddress2"
                                label="Apt/Suite/Notes"
                                sx={{ flexGrow: 1 }}
                            />
                        </FormControl>
                    </Fragment>
                ) : (
                    /* Goole auto complete pickup/drop off addresses */
                    <Fragment>
                        {/* Pickup spot */}
                        <FormControl
                            component="fieldset"
                            sx={{
                                mb: 4,
                                display: "flex",
                                gap: 2,
                            }}
                        >
                            <FormLabel
                                component="legend"
                                sx={{
                                    mb: 2,
                                    fontSize: 20,
                                    color: "primary.main",
                                }}
                            >
                                Pick Up Address
                            </FormLabel>

                            {/* error states handled internally  */}
                            <PlacesAutocomplete
                                label="Address"
                                id="pickUpPlace"
                                name="pickUpPlace"
                                validate={(address: ParsedAddress) => {
                                    return validateAddress(
                                        address.latitude,
                                        address.longitude,
                                        "pickUp"
                                    );
                                }}
                                loading={processing.pickUp}
                            />
                            <FormInput
                                multiline
                                name="pickUpAddress2"
                                label="Apt/Suite/Notes"
                            />
                        </FormControl>

                        {/* drop off spot */}
                        <FormControl
                            component="fieldset"
                            sx={{
                                mb: 4,
                                display: "flex",
                                gap: 2,
                            }}
                        >
                            <FormLabel
                                component="legend"
                                sx={{
                                    mb: 2,
                                    fontSize: 20,
                                    color: "primary.main",
                                }}
                            >
                                Drop Off Address
                            </FormLabel>

                            {/* error states handled internally  */}
                            <PlacesAutocomplete
                                label="Address"
                                id="dropOffPlace"
                                name="dropOffPlace"
                                validate={(address: ParsedAddress) => {
                                    return validateAddress(
                                        address.latitude,
                                        address.longitude,
                                        "dropOff"
                                    );
                                }}
                                loading={processing.dropOff}
                            />

                            <FormInput
                                multiline
                                name="dropOffAddress2"
                                label="Apt/Suite/Notes"
                            />
                        </FormControl>
                    </Fragment>
                )}

                {/* Trip program */}
                <TripPrograms
                    loading={processing.active}
                    geoFence={geoFence}
                    onChange={(evt) => {
                        handleTripProgram(evt.target.value);
                    }}
                />
            </Grid>
            {/* map view */}
            <Grid item xs={12} md={6}>
                <GoogleMap
                    loading={processing.active}
                    pickUpCoords={{
                        lat: values.pickUpPlace.latitude,
                        lng: values.pickUpPlace.longitude,
                    }}
                    dropOffCoords={{
                        lat: values.dropOffPlace.latitude,
                        lng: values.dropOffPlace.longitude,
                    }}
                    geoFence={geoFence}
                    sx={{
                        height: 450,
                        width: "100%",
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default memo(LocationSelector);
