import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import router from "./routing";
import store, { persistor } from "./redux/store";
import Splashscreen from "./components/Splashscreen";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const snackbarOptions: SnackbarProviderProps = {
    anchorOrigin: {
        horizontal: "right",
        vertical: "bottom",
    },
};

root.render(
    <StrictMode>
        <Provider store={store}>
            <PersistGate loading={<Splashscreen />} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SnackbarProvider {...snackbarOptions}>
                        <RouterProvider router={router} />
                    </SnackbarProvider>
                </LocalizationProvider>
            </PersistGate>
        </Provider>
    </StrictMode>
);
