import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "src/redux/store";
import { paraPlanApiUrl, device } from "src/utils/constants";
import { isPrefixedUrl } from "src/utils/helpers";

const rawBaseQuery = fetchBaseQuery({ baseUrl: paraPlanApiUrl });

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const state = api.getState() as RootState;
    const serviceUrl = state.auth.serviceUrl;
    const token = state.auth.token;
    const url = typeof args === "string" ? args : args.url;
    // check to make sure url is not fully qualified i.e has http:// or https://
    const isPrefixed = isPrefixedUrl(url);
    // gracefully handle scenarios where data to generate the URL is missing

    // there wont be a token at login so we login first
    if (!isPrefixed && (!serviceUrl || !token)) {
        return {
            error: {
                status: 400,
                statusText: "Bad Request",
                data: "missing token or serviceurl",
            },
        };
    }

    // construct a dynamically generated portion of the url
    const adjustedUrl = isPrefixed
        ? url
        : `${serviceUrl}${url}?Token=${token}&Device=${device}`;
    const adjustedArgs =
        typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery(adjustedArgs, api, extraOptions);
};

const API = createApi({
    reducerPath: "api",
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({}), // endpoints are extended in each module @see https://redux-toolkit.js.org/rtk-query/usage/code-splitting
});

export default API;
