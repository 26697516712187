import { useEffect, useState } from "react";
import {
    Grid,
    Stack,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";
import { FormInput, FormCheckbox } from "src/components";
import { ExpandMore } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { TripRequestFormValues } from "../types";
import { focusElement } from "../helpers";
import { tinykeys } from "tinykeys";

const panels = ["panel1", "panel2", "panel3", "all"];

const MoreInfoSelector = () => {
    const [expandedPanels, setExpandedPanels] = useState<string[]>([]);
    const { values } = useFormikContext<TripRequestFormValues>();

    function togglePanel(panel: string) {
        setExpandedPanels((current) => {
            if (panel === "all") {
                return current.length ? [] : panels;
            } else if (current.includes(panel)) {
                return current.filter((item) => item !== panel);
            }
            return [...current, panel];
        });
    }

    // register keyboard short cuts
    useEffect(() => {
        let unsubscribe = tinykeys(window, {
            "$mod+Alt+U": async (evt) => {
                evt.preventDefault();
                focusElement("more-information");
                await setExpandedPanels(panels);
                focusElement("clientInWC");
            },
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <Grid item xs={12} id="more-information" mb={2}>
            <Accordion
                expanded={expandedPanels.includes("all")}
                onChange={() => togglePanel("all")}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                        sx={{ p: 0.5, fontSize: 20, color: "primary.main" }}
                    >
                        More Information
                    </Typography>
                </AccordionSummary>

                <AccordionDetails
                    sx={{
                        mb: 4,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    {/* wheelchair */}
                    <Accordion
                        expanded={expandedPanels.includes("panel1")}
                        onChange={() => togglePanel("panel1")}
                    >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Stack
                                spacing={1}
                                width={"100%"}
                                direction={{
                                    xs: "column",
                                    md: "row",
                                }}
                            >
                                <Typography flexGrow={1}>Wheelchair</Typography>
                                <Typography
                                    component="small"
                                    color="text.secondary"
                                    flexGrow={4}
                                >
                                    {values.clientWCType
                                        ? values.clientWCType
                                        : "Please select wheelchair options"}
                                </Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack
                                spacing={1}
                                direction={{
                                    xs: "column",
                                    md: "row",
                                }}
                            >
                                <FormCheckbox
                                    id="clientInWC"
                                    name="clientInWC"
                                    label="I have a wheelchair"
                                    sx={{ flexGrow: 1 }}
                                />
                                <FormCheckbox
                                    name="clientNeedsWCVan"
                                    label="I need a wheelchair van"
                                    sx={{ flexGrow: 1 }}
                                />
                                <FormInput
                                    name="clientWCType"
                                    label="Wheelchair Type"
                                    sx={{ flexGrow: 1 }}
                                />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>

                    {/* Accessibility */}
                    <Accordion
                        expanded={expandedPanels.includes("panel2")}
                        onChange={() => togglePanel("panel2")}
                    >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Stack
                                spacing={1}
                                width={"100%"}
                                direction={{
                                    xs: "column",
                                    md: "row",
                                }}
                            >
                                <Typography flexGrow={1}>
                                    Accessibility
                                </Typography>
                                <Typography
                                    component="small"
                                    color="text.secondary"
                                    flexGrow={4}
                                >
                                    {values.clientIsDisabled
                                        ? "PWD"
                                        : "Ambulatory"}
                                </Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack
                                spacing={1}
                                direction={{
                                    xs: "column",
                                    md: "row",
                                }}
                            >
                                <FormCheckbox
                                    name="clientIsDisabled"
                                    label="I am a person with disability"
                                    sx={{ flexGrow: 1 }}
                                />
                                <FormInput
                                    name="clientDisabilities"
                                    label="Disabilities"
                                    sx={{ flexGrow: 1 }}
                                />
                                <FormInput
                                    name="clientEquipment"
                                    label="Other Equipment"
                                    sx={{ flexGrow: 1 }}
                                />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>

                    {/* Extra Riders */}
                    <Accordion
                        expanded={expandedPanels.includes("panel3")}
                        onChange={() => togglePanel("panel3")}
                    >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Stack
                                spacing={1}
                                width={"100%"}
                                direction={{
                                    xs: "column",
                                    md: "row",
                                }}
                            >
                                <Typography flexGrow={1}>
                                    Extra Riders
                                </Typography>
                                <Typography
                                    component="small"
                                    color="text.secondary"
                                    flexGrow={4}
                                >
                                    {values.personalCareAttendant
                                        ? Number(values.otherRiders) + 1
                                        : values.otherRiders}
                                </Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack
                                spacing={1}
                                direction={{
                                    xs: "column",
                                    md: "row",
                                }}
                            >
                                <FormCheckbox
                                    name="personalCareAttendant"
                                    label="I travel with a Personal Care Attendant"
                                    sx={{ flexGrow: 1 }}
                                />
                                <FormInput
                                    type="number"
                                    name="otherRiders"
                                    label="Other Riders (not PCA)"
                                    sx={{ flexGrow: 1 }}
                                />
                                <FormInput
                                    name="carSeatDescription"
                                    label="Car Seat information"
                                    sx={{ flexGrow: 1 }}
                                />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>

                    <FormInput
                        multiline
                        minRows={5}
                        fullWidth
                        name="tripComments"
                        label="Trip Comments"
                    />
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};

export default MoreInfoSelector;
