import { Outlet } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { authAPI } from "src/modules/auth/redux/authSlice";
import Splashscreen from "../Splashscreen";

/**
 *  loads agency config from url param
 *  @param agency
 *  @example /login?agency=ENGRAPHTRANSIT
 */

const AuthLayout = () => {
    const [searchParams] = useSearchParams();
    const agency = searchParams.get("agency") ?? "";

    /* if agency is present, we prefetch their config url then their config */
    const { data, isLoading: urlMapperLoading } = authAPI.useGetUrlMapperQuery(
        agency,
        {
            skip: !agency,
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
        }
    );

    const { isLoading: loadingAgencyConfig } = authAPI.useGetAgencyConfigQuery(
        {
            agency,
            url: data?.entity ?? "",
        },
        {
            skip: !agency || !data?.entity,
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
        }
    );

    if (loadingAgencyConfig || urlMapperLoading) {
        return <Splashscreen description="processing" />;
    }

    return <Outlet />;
};

export default AuthLayout;
