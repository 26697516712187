import {
    Box,
    BoxProps,
    Avatar,
    Stack,
    Typography,
    CircularProgress,
} from "@mui/material";
import logoImg from "src/images/logo-full-green.png";

interface Props extends BoxProps {
    description?: string;
}
const Splashscreen = ({ description, sx }: Props) => {
    return (
        <Box
            component="main"
            sx={{
                display: "grid",
                placeItems: "center",
                height: "100vh",
                backgroundColor: "background.default",
                ...sx,
            }}
        >
            <Stack
                spacing={3}
                justifyContent="center"
                useFlexGap
                alignItems="center"
            >
                <Avatar
                    alt="logo"
                    src={logoImg}
                    variant="square"
                    sx={{
                        mx: "auto",
                        height: 100,
                        width: 300,
                        objectFit: "",
                    }}
                />

                <CircularProgress color="inherit" size={55} sx={{ my: 8 }} />
                <Typography align="center" variant="h6" fontWeight={400}>
                    {description}
                </Typography>
            </Stack>
        </Box>
    );
};

export default Splashscreen;
