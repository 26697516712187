import { Outlet, ScrollRestoration } from "react-router-dom";
import {
    ThemeProvider,
    createTheme,
    CssBaseline,
    responsiveFontSizes,
} from "@mui/material";
import { useAppSelector } from "src/hooks";
import { colors } from "src/utils/constants";

function getHexColor(color: string | undefined) {
    if (!color || typeof color !== "string") return;
    return `#${color.substring(3)}`;
}

const ThemedLayout = () => {
    const preferences = useAppSelector(
        (state) => state.auth.config?.ConnectPreferences
    );

    const colorConfig = {
        primary: preferences?.DefaultTintString,
        secondary: preferences?.SecondaryTintString,
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: getHexColor(colorConfig.primary) ?? colors.primary,
            },
            secondary: {
                main: getHexColor(colorConfig.secondary) ?? colors.secondary,
            },
        },
    });

    const responsiveTheme = responsiveFontSizes(theme);

    return (
        <ThemeProvider theme={responsiveTheme}>
            <Outlet />
            <CssBaseline />
            <ScrollRestoration />
        </ThemeProvider>
    );
};

export default ThemedLayout;
