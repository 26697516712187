import { ChangeEventHandler, useState } from "react";
import { FieldValidator, useField } from "formik";
import {
    TextField,
    TextFieldProps,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface Props extends Omit<TextFieldProps, "name"> {
    name: string;
    valueAsNumber?: boolean;
    onChange?: ChangeEventHandler;
    validate?: FieldValidator;
}

const FormInput = ({
    type,
    name,
    label,
    helperText,
    valueAsNumber,
    onChange,
    validate,
    ...rest
}: Props) => {
    const [show, setShow] = useState(false);
    const [field, meta, helpers] = useField({ name, validate });

    return (
        <TextField
            {...field}
            key={name}
            label={label}
            variant="standard"
            onChange={(evt) => {
                if (valueAsNumber) {
                    helpers.setValue(parseInt(evt.target.value));
                } else {
                    field.onChange(evt);
                }
                // if custom change event was passsed
                if (onChange) {
                    onChange(evt);
                }
            }}
            error={meta.error ? true : false}
            helperText={meta.error ? meta.error : helperText}
            type={show ? "text" : type}
            InputProps={{
                endAdornment: type === "password" && (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShow(!show)}
                        >
                            {show ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...rest}
        />
    );
};

export default FormInput;
