// shared helper functions

import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import type { Program } from "src/modules/auth/types";
import { FormikErrors } from "formik";
import { enqueueSnackbar } from "notistack";
import { PassioService } from "src/types";

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 * @see https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
 */
export function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return (
        typeof error === "object" &&
        error != null &&
        "status" in error &&
        "data" in error
    );
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
    error: unknown
): error is { message: string } {
    return (
        typeof error === "object" &&
        error != null &&
        "message" in error &&
        typeof (error as any).message === "string"
    );
}

export function isPrefixedUrl(url = "") {
    return url.includes("http");
}

export function filterFundingSources(programs: Program[] = []) {
    return programs
        ?.filter((program) => {
            return (
                (!program.membershipIsRequired || program.membershipID) &&
                !program.archived &&
                !program.deleted
            );
        })
        .map((program) => program.programName);
}

export function dateFromJson(json: any) {
    var a = json.split(/[^0-9]/);
    var d = new Date(a[0], a[1] - 1, a[2]);
    return d;
}

export function dateAndTimeFromJson(json: any) {
    var a = json.split(/[^0-9]/);
    var d = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
    return d;
}

export function getEpochTime(date: Date | string) {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    return parsedDate && parsedDate.getTime() / 1000;
}

export function alertIfMissingFields(errors: FormikErrors<any>) {
    if (Object.keys(errors).length) {
        enqueueSnackbar(
            "Oops, you missed a few fields, please check and fill them",
            {
                variant: "error",
            }
        );
    }
}

export async function getGeolocation() {
    const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return position as GeolocationPosition;
}

export function getServiceBrandName(service: PassioService) {
    return service === PassioService.navigator ? "Navigator" : "ParaPlan";
}

export function getService() {
    const searchParams = new URLSearchParams(window.location.search);
    const param = searchParams.get("service") as keyof typeof PassioService;

    if (param && PassioService[param]) {
        return PassioService[param];
    }

    return PassioService.paraplan;
}


export function isValidCoord(coords: google.maps.LatLngLiteral | any): boolean {
    let isValid = false;
    if (
        coords &&
        coords.lat &&
        coords.lat !== 0 &&
        coords.lng &&
        coords.lng !== 0
    ) {
        isValid = true;
    }

    return isValid;
}
