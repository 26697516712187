import { useEffect } from "react";
import {
    Container,
    Typography,
    Box,
    Stack,
    Button,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { DataGrid, GridLogicOperator } from "@mui/x-data-grid";
import { AddCircle, PeopleOutline } from "@mui/icons-material";
import { useAppSelector } from "src/hooks";
import { useNavigate } from "react-router-dom";
import { authSelector } from "../auth/redux/authSlice";
import { useTitle } from "react-use";
import { columns } from "./helpers";
import { authAPI } from "../auth/redux/authSlice";
import { PassioService } from "src/types";
import CustomToolbar from "./components/CustomToolbar";

const Riders = () => {
    useTitle("Riders");
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"));
    const { isManager, service } = useAppSelector(authSelector);

    const { data, isLoading, isFetching, refetch } = authAPI.useGetClientsQuery(
        false,
        {
            skip: !isManager,
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
        }
    );

    // redirect if user is not a mobility manager
    useEffect(() => {
        if (!isManager) {
            navigate("/trips");
        }
    }, [isManager, navigate]);

    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                my={4}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <PeopleOutline fontSize="large" />
                    <Typography variant="h4" component="h1">
                        Riders
                    </Typography>
                </Stack>

                {service === PassioService.paraplan && (
                    <Button
                        startIcon={<AddCircle />}
                        variant="contained"
                        sx={{ p: 1.5 }}
                        onClick={() => navigate("/riders/new")}
                    >
                        New Rider
                    </Button>
                )}
            </Stack>
            <Box>
                <DataGrid
                    autoHeight
                    columns={columns}
                    rows={data?.list || []}
                    getRowHeight={() => "auto"}
                    loading={isLoading || isFetching}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                        toolbar: {
                            quickFilterProps: { debounceMs: 500 },
                            refreshTable: refetch,
                        },
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: isMobileDevice ? 5 : 10,
                            },
                        },
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterLogicOperator: GridLogicOperator.Or,
                            },
                        },
                        columns: {
                            columnVisibilityModel: {
                                mobileCard: isMobileDevice,
                                name: !isMobileDevice,
                                birthdate: !isMobileDevice,
                                phone: !isMobileDevice,
                                email: !isMobileDevice,
                                dummy: !isMobileDevice,
                            },
                        },
                    }}
                    sx={{
                        p: 0.5,
                        ...(isMobileDevice && {
                            border: 0,
                            "& .MuiDataGrid-cell": {
                                p: 0,
                                border: 0,
                                bgcolor: "white",
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: "none",
                            },
                        }),
                    }}
                />
            </Box>
        </Container>
    );
};

export default Riders;
