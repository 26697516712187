import { Fragment, useState, useEffect } from "react";
import {
    Grid,
    Stack,
    Button,
    MenuItem,
    FormControl,
    FormLabel,
    Container,
    Typography,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
    FormInput,
    FormTimePicker,
    FormDatePicker,
    Splashscreen,
    FormDateTimePicker,
} from "src/components";
import {
    DepartureBoardOutlined,
    AccountCircleOutlined,
} from "@mui/icons-material";
import { Formik, Form, FormikHelpers } from "formik";
import {
    omitFields,
    initTripRequest,
    OUT_OF_ZONE_ERROR,
    SAME_ADDRESS_ERROR,
    validateByCalendar,
    formatAddressFields,
    focusElement,
    returnTripOptions,
} from "./helpers";
import { TripRequestSchema } from "./schema";
import { useAppSelector, useSelectedClient } from "src/hooks";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { enqueueSnackbar } from "notistack";
import { tripsAPI } from "../../redux/tripSlice";
import { useTitle } from "react-use";
import { alertIfMissingFields, isErrorWithMessage } from "src/utils/helpers";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { TripRequestFormValues } from "./types";
import { TimeTypes } from "src/types";
import { omit, isEqual } from "lodash";
import { tinykeys } from "tinykeys";
import { getProperty } from "dot-prop";

import LocationSelector from "./components/LocationSelector";
import MoreInfoSelector from "./components/MoreInfoSelector";
import ShortcutsDialog from "src/components/ShortcutsDialog";
import moment from "moment";

const TripRequest = () => {
    useTitle("Request a ride");
    const { id } = useParams(); // managers can request rides for clients
    const navigate = useNavigate();
    const location = useLocation();

    const [showInfo, setShowInfo] = useState(false);
    const [showShortcuts, setShowShortcuts] = useState(false);
    const [initValues, setInitValues] = useState<TripRequestFormValues | any>(
        {}
    );
    const [processing, setProcessing] = useState({
        active: true,
        message: "Initializing Trip",
    });
    const [requestTrip, { isLoading }] = tripsAPI.useRequestTripMutation();
    const { user, isManager, config, availableFundingSources } =
        useAppSelector(authSelector);

    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"));

    const preferences = config?.ConnectPreferences;
    const canRequestAnyPULocation = preferences?.CanRequestAnyPULocation;
    const client = useSelectedClient(id);

    // initialize trip
    useEffect(() => {
        (async () => {
            const values = await initTripRequest(
                client,
                user?.Email,
                canRequestAnyPULocation
            );

            // default to first available funding source if trip program is not set
            if (!values.tripProgram) {
                values.tripProgram = availableFundingSources[0];
            }

            // if a trip duplication was requested
            if (location.state?.duplicate) {
                setInitValues({
                    ...values,
                    ...location.state.duplicate,
                });
            } else {
                setInitValues(values);
            }

            setProcessing({ active: false, message: "" });
        })();
    }, [
        client,
        user?.Email,
        availableFundingSources,
        canRequestAnyPULocation,
        location.state,
    ]);

    // register keyboard short cuts
    useEffect(() => {
        let unsubscribe = tinykeys(window, {
            // focus pick up address
            "$mod+Alt+P": (evt) => {
                evt.preventDefault();
                focusElement("pickUpPlace");
            },
            // focus drop off address
            "$mod+Alt+O": (evt) => {
                evt.preventDefault();
                focusElement("dropOffPlace");
            },
            // focus time type
            "$mod+Alt+I": (evt) => {
                evt.preventDefault();
                focusElement("timeType");
            },
            // show shortcuts dialog
            "$mod+Alt+H": (evt) => {
                evt.preventDefault();
                setShowShortcuts(true);
            },
        });
        return () => {
            unsubscribe();
        };
    }, []);

    async function handleRequest(
        values: TripRequestFormValues,
        helpers: FormikHelpers<TripRequestFormValues>
    ) {
        // ensure Pickup and dropOff locations are different
        if (
            isEqual(
                values.pickUpPlace.description,
                values.dropOffPlace.description
            )
        ) {
            helpers.setFieldError(
                "dropOffPlace.description",
                SAME_ADDRESS_ERROR
            );
            enqueueSnackbar(SAME_ADDRESS_ERROR, { variant: "error" });
            return;
        }

        const url = new URL(window.location.href);
        const trip = {
            ...omit(values, omitFields),
            ...formatAddressFields("dropOff", values.dropOffPlace),
            ...formatAddressFields("pickUp", values.pickUpPlace),
            apptTimeEpoch: moment(values.apptTime).unix(),
            pickUpTimeEpoch: moment(values.pickUpTime).unix(),
            dropOffTimeEpoch: moment(values.dropOffTime).unix(),
            returnTimeEpoch: moment(values.returnTime).unix(),
            clientBirthdateEpoch: moment(values.clientBirthdate).unix(),
            tripUrl: `${url?.origin}/trips/${values.importTripID}`,
            clientRESTUrl: user.RESTUrl,
            paraPlanClientID: user.ClientID,
        };

        try {
            // Note: trip is wrapped in an array because the api expects an array of trips
            const response = await requestTrip([trip]).unwrap();

            enqueueSnackbar(
                "Trip Requested successfully. A confirmation email will be sent shortly",
                {
                    variant: "success",
                }
            );

            const tripId = response.AddedTrips[0];
            // if manager requesting trip go back to riders, reset state incase a trip was duplicated
            if (id && isManager) {
                navigate(`/trips/${tripId}?status=live&rider=${id}`, {
                    state: {},
                });
                return;
            }
            navigate(`/trips/${tripId}?status=live`, { state: {} });
        } catch (error) {
            if (isErrorWithMessage(error)) {
                enqueueSnackbar(error.message, { variant: "error" });
            } else {
                enqueueSnackbar(
                    "Trip request was unsuccessfull, please try again",
                    {
                        variant: "error",
                    }
                );
            }
        }
    }

    // initializing trip could take a while
    if (processing.active) {
        return <Splashscreen description={processing.message} />;
    }

    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            <Formik
                initialValues={initValues}
                validationSchema={TripRequestSchema}
                onSubmit={handleRequest}
            >
                {({ values, errors, setFieldValue, isValidating }) => {
                    /**
                     *  Effectively disallow form submission if addresses are similar or out of zone
                     *  without showing a frustrating disabled button
                     */
                    const pickupAddressError = getProperty(
                        errors.pickUpPlace,
                        "description"
                    );
                    const dropOffAddressError = getProperty(
                        errors.dropOffPlace,
                        "description"
                    );
                    const shouldNotSubmit =
                        isValidating ||
                        pickupAddressError === OUT_OF_ZONE_ERROR ||
                        dropOffAddressError === OUT_OF_ZONE_ERROR ||
                        dropOffAddressError === SAME_ADDRESS_ERROR;

                    const disablePast = moment(values.apptTime).isBefore(
                        new Date()
                    );
                    return (
                        <Form>
                            {/* Header */}
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="space-between"
                                my={4}
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <DepartureBoardOutlined fontSize="large" />
                                    <Typography variant="h4" component="h1">
                                        Request A Ride
                                    </Typography>
                                </Stack>

                                {id && isManager && (
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <AccountCircleOutlined fontSize="large" />
                                        <Typography
                                            variant="h5"
                                            component="span"
                                        >
                                            {values.clientFirstName}{" "}
                                            {values.clientLastName}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            sx={{ ml: 2 }}
                                            onClick={() =>
                                                setShowInfo(!showInfo)
                                            }
                                        >
                                            {showInfo ? "Hide" : "View"}
                                        </Button>
                                    </Stack>
                                )}
                            </Stack>

                            <Grid container>
                                {/* Rider details only visible to managers */}
                                {isManager && showInfo && (
                                    <Grid item xs={12}>
                                        <FormControl
                                            component="fieldset"
                                            sx={{
                                                mb: 4,
                                                display: "flex",
                                                gap: 2,
                                            }}
                                        >
                                            <FormLabel
                                                component="legend"
                                                sx={{
                                                    mb: 2,
                                                    fontSize: 20,
                                                    color: "primary.main",
                                                }}
                                            >
                                                Rider
                                            </FormLabel>

                                            {/* Rider Information */}
                                            <Stack
                                                spacing={2}
                                                direction={{
                                                    xs: "column",
                                                    md: "row",
                                                }}
                                            >
                                                <FormInput
                                                    name="clientFirstName"
                                                    label="First Name"
                                                    sx={{ flexGrow: 1 }}
                                                />
                                                <FormInput
                                                    name="clientLastName"
                                                    label="Last Name"
                                                    sx={{ flexGrow: 1 }}
                                                />
                                            </Stack>

                                            <Stack
                                                spacing={2}
                                                direction={{
                                                    xs: "column",
                                                    md: "row",
                                                }}
                                            >
                                                <FormInput
                                                    type="tel"
                                                    required
                                                    name="clientPhone"
                                                    label="Phone"
                                                    sx={{ flexGrow: 1 }}
                                                    autoComplete="tel"
                                                />

                                                <FormDatePicker
                                                    format="MM/dd/yyyy"
                                                    name="clientBirthdate"
                                                    label="Birthday"
                                                    sx={{ flexGrow: 1 }}
                                                />

                                                <FormInput
                                                    type="email"
                                                    name="clientEmail"
                                                    label="Rider Email"
                                                    sx={{ flexGrow: 1 }}
                                                />
                                            </Stack>

                                            {/* requester details */}
                                            <Stack spacing={2}>
                                                <FormInput
                                                    type="email"
                                                    name="requesterEmail"
                                                    label="Requester Email"
                                                    sx={{ flexGrow: 1 }}
                                                />

                                                <FormInput
                                                    multiline
                                                    minRows={5}
                                                    fullWidth
                                                    name="clientComments"
                                                    label="Comments"
                                                />
                                            </Stack>
                                        </FormControl>
                                    </Grid>
                                )}

                                {/* Location Selector  */}
                                <LocationSelector />

                                {/* times */}
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        p: 2,
                                        mb: 4,
                                        boxShadow: 1,
                                        borderRadius: 1,
                                    }}
                                >
                                    <FormControl
                                        component="fieldset"
                                        sx={{ mb: 4, display: "flex", gap: 2 }}
                                    >
                                        <FormLabel
                                            component="legend"
                                            sx={{
                                                mb: 2,
                                                fontSize: 20,
                                                color: "primary.main",
                                            }}
                                        >
                                            Times
                                        </FormLabel>

                                        {/* pick up times */}
                                        <Stack
                                            spacing={2}
                                            direction={{
                                                xs: "column",
                                                md: "row",
                                            }}
                                        >
                                            <FormInput
                                                id="timeType"
                                                name="timeType"
                                                label="I want to be"
                                                select
                                                SelectProps={{
                                                    native: isMobileDevice,
                                                }}
                                                sx={{ flexGrow: 1 }}
                                                disabled={
                                                    !preferences.CanCreateApptRequest
                                                }
                                            >
                                                {Object.values(TimeTypes).map(
                                                    (item, index) =>
                                                        isMobileDevice ? (
                                                            <option
                                                                key={index}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </option>
                                                        ) : (
                                                            <MenuItem
                                                                key={index}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                )}
                                            </FormInput>

                                            <FormDateTimePicker
                                                disablePast
                                                name="apptTime"
                                                label={
                                                    values.timeType ===
                                                    TimeTypes.droppedOff
                                                        ? "Appointment Date and Time"
                                                        : "Trip Date and Pick Up Time"
                                                }
                                                sx={{ flexGrow: 1 }}
                                                validate={async (date) => {
                                                    return await validateByCalendar(
                                                        client,
                                                        date,
                                                        values.tripProgram
                                                    );
                                                }}
                                                onChange={async (date) => {
                                                    // update return time, add two hours
                                                    await setFieldValue(
                                                        "returnTime",
                                                        moment(date)
                                                            .add(2, "h")
                                                            .toDate(),
                                                        false
                                                    );

                                                    await setFieldValue(
                                                        "pickUpTime",
                                                        date,
                                                        false
                                                    );

                                                    await setFieldValue(
                                                        "dropOffTime",
                                                        date,
                                                        false
                                                    );
                                                }}
                                            />
                                        </Stack>

                                        {/* return trip */}
                                        {preferences?.CanRequestReturnTrip && (
                                            <Stack
                                                spacing={2}
                                                direction={{
                                                    xs: "column",
                                                    md: "row",
                                                }}
                                            >
                                                {/* How the rider is going to get back. 0 = One way, 1 = Call for return, 2 = Specific return time requested */}
                                                <FormInput
                                                    name="returnType"
                                                    label="For my return trip, I'd like"
                                                    select
                                                    valueAsNumber
                                                    SelectProps={{
                                                        native: isMobileDevice,
                                                    }}
                                                    sx={{ flexGrow: 1 }}
                                                >
                                                    {returnTripOptions.map(
                                                        (item, index) =>
                                                            isMobileDevice ? (
                                                                <option
                                                                    key={index}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </option>
                                                            ) : (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                    )}
                                                </FormInput>
                                                {/* if roundtrip */}
                                                {values.returnType === 2 && (
                                                    <Fragment>
                                                        <FormTimePicker
                                                            name="returnTime"
                                                            label="Return Pick Up Time"
                                                            sx={{ flexGrow: 1 }}
                                                            disablePast={
                                                                disablePast
                                                            }
                                                            minTime={
                                                                values.apptTime
                                                            }
                                                            validate={(
                                                                date
                                                            ) => {
                                                                return validateByCalendar(
                                                                    client,
                                                                    date,
                                                                    values.tripProgram,
                                                                    "time"
                                                                );
                                                            }}
                                                        />
                                                    </Fragment>
                                                )}
                                            </Stack>
                                        )}
                                    </FormControl>
                                </Grid>

                                {/* more information */}
                                <MoreInfoSelector />

                                <Grid item xs={12} md={4} mx="auto">
                                    <LoadingButton
                                        fullWidth
                                        type={
                                            // we could just disable but that tends to be frustrating
                                            shouldNotSubmit
                                                ? "button"
                                                : "submit"
                                        }
                                        variant="contained"
                                        sx={{ py: 1.5 }}
                                        loading={isLoading || isValidating}
                                        onClick={() =>
                                            alertIfMissingFields(errors)
                                        }
                                    >
                                        Request trip
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
            <ShortcutsDialog
                open={showShortcuts}
                onClose={() => setShowShortcuts(false)}
            />
        </Container>
    );
};

export default TripRequest;
