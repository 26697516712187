import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogProps,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";

interface Props extends Omit<DialogProps, "onClose"> {
    onClose: () => void;
}

const ShortcutsDialog = ({ open, onClose }: Props) => {
    return (
        <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="title">
            <DialogTitle id="title">Keyboard shortcuts</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    The following shortcuts are available when requesting a trip
                </DialogContentText>

                <List sx={{ my: 2 }}>
                    <ListItem disableGutters>
                        <ListItemText
                            primary=" ⌘ + Alt + P"
                            secondary="Focus on the pickup address field"
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary=" ⌘ + Alt + O"
                            secondary="Focus on the dropoff address field"
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary=" ⌘ + Alt + I"
                            secondary="Focus on the pickup type selector"
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary=" ⌘ + Alt + U"
                            secondary="Focus on the more information section, hilight the first checkbox"
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary=" ⌘ + Alt + H"
                            secondary="Open help dialog"
                        />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShortcutsDialog;
