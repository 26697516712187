import { ReactNode } from "react";
import { Box, Button, Avatar, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logoImg from "src/images/logo-full-green.png";

interface Props {
    title?: string;
    description?: string;
    action?: ReactNode;
}

const Error = ({
    description = "Sorry an error occured",
    action,
    title,
}: Props) => {
    const navigate = useNavigate();

    return (
        <Container
            component="main"
            sx={{
                display: "grid",
                placeItems: "center",
                minHeight: "75vh",
                textAlign: "center",
            }}
            maxWidth="md"
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar
                    alt="logo"
                    src={logoImg}
                    variant="square"
                    sx={{
                        my: 4,
                        mx: "auto",
                        height: 100,
                        width: 300,
                        objectFit: "",
                    }}
                />

                {title && (
                    <Typography variant="h4" component="h2" my={2}>
                        {title}
                    </Typography>
                )}

                <Typography component="p" variant="h5" my={2}>
                    {description}
                </Typography>

                {action ? (
                    action
                ) : (
                    <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, py: 1.5, color: "white" }}
                        onClick={() => navigate(-1)}
                    >
                        Go Back
                    </Button>
                )}
            </Box>
        </Container>
    );
};

export default Error;
