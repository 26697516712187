import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "src/redux";
import API from "src/redux/api";
import { authAPI } from "src/modules/auth/redux/authSlice";
import type {
    GeoFence,
    GeofenceRequest,
    GeoEligibilityCheckRequest,
    CancelTripOfReservationRequest,
    CancelReservationRequest,
    CancelTripRequest,
    RemoveTripRequest,
    TripStatusRequest,
    TripStatusResponse,
} from "../types";
import type {
    GetTripsResponse,
    TripRequestFormValues,
    TripResponse,
} from "../pages/TripRequest/types";
import moment from "moment";
// Define a type for the slice state
interface TripState {
    returnTripType: "One-Way" | "Round Trip" | "Callback";
}

// Define the initial state using that type
const initialState: TripState = {
    returnTripType: "One-Way",
};

export const tripsAPI = API.injectEndpoints({
    endpoints: (build) => ({
        getTrips: build.query<GetTripsResponse, number>({
            query: (id) => ({
                url: "TripService/TripsByClient",
                params: {
                    ClientID: id,
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && result?.success;
                },
            }),
        }),
        requestTrip: build.mutation<
            TripResponse,
            Partial<TripRequestFormValues>[]
        >({
            query: (data) => ({
                url: "TripService/AddTripRequest",
                body: data,
                method: "post",
                validateStatus: (response, result) => {
                    return (
                        response.status === 200 &&
                        result.success &&
                        result?.AddedTrips?.length > 0
                    );
                },
            }),
        }),
        checkGeographicEligibilty: build.mutation<
            GeoFence[],
            GeoEligibilityCheckRequest
        >({
            query: (data) => ({
                url: `https://passio3.com/${data.passioAgency}/passioTransit/geofence/at/${data.latitude},${data.longitude}`,
                params: {
                    accessToken: data.passioToken,
                },
            }),
        }),
        getGeofence: build.mutation<GeoFence, GeofenceRequest>({
            query: (data) => ({
                url: `https://passio3.com/${data.passioAgency}/passioTransit/geofence/${data.id}/`,
                params: {
                    accessToken: data.passioToken,
                },
            }),
        }),
        cancelTripOfReservation: build.mutation<
            any,
            CancelTripOfReservationRequest
        >({
            query: (data) => ({
                url: "ReservationService/CancelSingleTrip",
                body: data,
                method: "post",
                validateStatus: (response, result) => {
                    return response.status === 200 && result?.success;
                },
            }),
        }),
        cancelReservation: build.mutation<any, CancelReservationRequest>({
            query: (data) => ({
                url: "ReservationService/CancelReservation/",
                body: data,
                method: "post",
                validateStatus: (response, result) => {
                    return response.status === 200 && result?.success;
                },
            }),
        }),
        cancelTrip: build.mutation<any, CancelTripRequest>({
            query: (data) => ({
                url: `TripService/Trips/AddAction2/${data.id}/2`,
                params: {
                    Timestamp: moment().local().unix(),
                    Value: data.reason,
                    lat: data.lat,
                    lng: data.lng,
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && result?.success;
                },
            }),
        }),
        removeTripRequest: build.mutation<any, RemoveTripRequest>({
            query: (data) => ({
                url: "TripService/RemoveTripRequest",
                params: {
                    TripStatus: data.reason,
                    Reason: data.reason,
                    NotifyRider: true,
                },
                body: {
                    importTripID: data.id,
                },
                method: "post",
                validateStatus: (response, result) => {
                    return response.status === 200 && result?.success;
                },
            }),
        }),
        getTripStatus: build.query<
            TripStatusResponse["entity"],
            TripStatusRequest
        >({
            query: (data) => ({
                url: `RideService/Status`,
                body: data,
                method: "post",
                validateStatus: (response, result) => {
                    return (
                        response.status === 200 &&
                        result?.success &&
                        result?.entity &&
                        !result?.errorMessage
                    );
                },
            }),
            transformResponse: (response: TripStatusResponse) =>
                response.entity,
        }),
    }),
    overrideExisting: false,
});

const tripSlice = createSlice({
    name: "trips",
    initialState,
    reducers: {
        save: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        clear: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authAPI.endpoints.getConfig.matchFulfilled,
            (state, { payload }) => {
                let gCost4 = payload.entity?.gCost4;
                if (gCost4 === "Roundtrip: Call for return") {
                    state.returnTripType = "Callback";
                }
                if (gCost4 === "Roundtrip: Pick up at ->") {
                    state.returnTripType = "Round Trip";
                }
            }
        );
    },
});

// Other code such as selectors can use the imported `RootState` type
export const tripsSelector = (state: RootState) => state.trips;
export const tripActions = tripSlice.actions;

export default tripSlice.reducer;
