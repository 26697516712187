import {
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Typography, Stack } from "@mui/material";
import { OpenInNewOutlined } from "@mui/icons-material";
import { ManagedClient } from "src/modules/auth/types";
import { Link } from "react-router-dom";
import moment from "moment";
import TableActions from "./components/TableActions";
import ClientCard from "./components/ClientCard";

export const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 2,
        description: "Riders name",
        renderCell: ({ value, row }: GridRenderCellParams<ManagedClient>) => {
            return (
                <Typography
                    variant="body2"
                    color="text.secondary"
                    py={1.5}
                    component={Link}
                    to={`/riders/${row.id}`}
                >
                    <Stack spacing={1} direction="row">
                        <OpenInNewOutlined fontSize="small" />
                        <span> {value || "N/A"}</span>
                    </Stack>
                </Typography>
            );
        },
        minWidth: 290,
    },
    {
        field: "birthdate",
        headerName: "Date Of Birth",
        flex: 1,
        description: "Riders date of birth",
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => {
            return value ? moment(value).format("MM-DD-YYYY") : "N/A";
        },
        minWidth: 290,
    },
    {
        field: "phone",
        headerName: "Phone Number",
        flex: 1,
        description: "Riders phone number",
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => {
            return value ? `+${value}` : "N/A";
        },
        minWidth: 290,
    },
    {
        field: "email",
        headerName: "Email",
        flex: 1,
        description: "Riders email address",
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => {
            return value || "N/A";
        },
        minWidth: 290,
    },
    {
        field: "dummy",
        headerName: "Actions",
        flex: 1,
        description: "Actions your can perfom on this trip",
        disableColumnMenu: true,
        filterable: false,
        renderCell: TableActions,
        minWidth: 290,
    },
    {
        field: "mobileCard",
        headerName: "All Riders",
        flex: 1,
        description: "Full client details",
        disableColumnMenu: true,
        renderCell: ClientCard,
        minWidth: 290,
    },
];
