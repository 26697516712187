import { useEffect, useMemo, useRef, useState, useCallback } from "react";

interface Props {
    map: google.maps.Map | null;
    pickUpCoords?: google.maps.LatLngLiteral;
    dropOffCoords?: google.maps.LatLngLiteral;
    vehicleCoords?: google.maps.LatLngLiteral;
    riderCoords?: google.maps.LatLngLiteral;
}

const useDirections = ({
    map,
    pickUpCoords,
    dropOffCoords,
    vehicleCoords,
    riderCoords,
}: Props) => {
    const service = useRef<google.maps.DirectionsService | null>(null);
    const renderer = useRef<google.maps.DirectionsRenderer | null>(null);
    const [directions, setDirections] =
        useState<google.maps.DirectionsResult | null>(null);

    const [routeMeta, setRouteMeta] = useState({
        distance: "",
        duration: "",
    });

    const options = useMemo(() => {
        let origin = pickUpCoords;
        let destination = dropOffCoords;

        // when driver and rider are present switch origin
        if (
            vehicleCoords &&
            vehicleCoords.lat &&
            vehicleCoords.lng &&
            riderCoords &&
            riderCoords.lat &&
            riderCoords.lng
        ) {
            origin = vehicleCoords;
        }

        const mode = window?.google?.maps?.TravelMode
            ? google.maps.TravelMode.DRIVING
            : ("DRIVING" as google.maps.TravelMode);

        return {
            origin: origin || "",
            destination: destination || "",
            travelMode: mode,
            provideRouteAlternatives: false,
        };
    }, [pickUpCoords, dropOffCoords, vehicleCoords, riderCoords]);

    const callback = useCallback(
        (
            result: google.maps.DirectionsResult | null,
            status: google.maps.DirectionsStatus
        ) => {
            if (status === "OK") {
                if (result) {
                    renderer.current?.setDirections(result);
                    setDirections(result);
                    const { distance, duration } = result.routes[0].legs[0];
                    setRouteMeta({
                        distance: distance?.text ?? "",
                        duration: duration?.text ?? "",
                    });
                }
            } else {
                setDirections(null);
                setRouteMeta({
                    distance: "",
                    duration: "",
                });
            }
        },
        []
    );

    useEffect(() => {
        // only procceed if map is loading
        if (map) {
            // check and initialize service and renderer
            if (!service.current) {
                service.current = new google.maps.DirectionsService();
            }
            if (!renderer.current) {
                renderer.current = new google.maps.DirectionsRenderer();
            }
            // set map renderer
            renderer.current.setMap(map);
            // plot directions
            service.current.route(options, callback);
        }
    }, [map, service, renderer, options, callback]);

    return {
        routeMeta,
        directions,
    };
};

export default useDirections;
