export const defaultValues = {
    name: "",
    address1: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    latitude: 0,
    longitude: 0,
    description: "",
};
