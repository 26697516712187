import { useEffect } from "react";

import {
    Container,
    Typography,
    Stack,
    FormControl,
    FormLabel,
    Card,
    Box,
} from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, FormikValues } from "formik";
import { Link, useNavigate } from "react-router-dom";
import {
    FormInput,
    FormDatePicker,
    FormCheckbox,
    PhoneNumberInput,
} from "src/components";
import { useTitle } from "react-use";
import { authAPI, authSelector } from "../redux/authSlice";
import { enqueueSnackbar } from "notistack";
import { signupSchema } from "../schema";
import { alertIfMissingFields, isFetchBaseQueryError } from "src/utils/helpers";
import { useAppSelector } from "src/hooks";
import moment from "moment";

const Signup = () => {
    useTitle("Connect Signup");
    const navigate = useNavigate();
    const [signup, { isLoading }] = authAPI.useSignupMutation();
    const { serviceUrl, config } = useAppSelector(authSelector);
    const agency = config?.agencyId ?? "";

    async function handleSignup(values: FormikValues) {
        const request = {
            serviceUrl,
            agency,
            data: {
                password: values.password,
                name: `${values.firstName} ${values.lastName}`,
                birthdateEpoch: moment(values.birthdate).unix(),
                defaultProgramMembershipID: "",
                email: values.email,
                phone: values.phone,
                accessibility: {
                    hasPCA: values?.accessibility?.hasPCA,
                    usesWheelchair: values?.accessibility?.usesWheelchair,
                    wheelChairType: values?.accessibility?.wheelChairType,
                },
                home: {
                    ...values.home,
                    lat: 0,
                    lng: 0,
                },
            },
        };

        try {
            await signup(request).unwrap();
            enqueueSnackbar("Account created successfully", {
                variant: "success",
            });
            navigate("/login");
        } catch (error) {
            if (isFetchBaseQueryError(error)) {
                enqueueSnackbar(`${(error.data as any)?.errorMessage}`, {
                    variant: "error",
                });
            } else {
                enqueueSnackbar("Sorry, an error occured. Please try again", {
                    variant: "error",
                });
            }
        }
    }

    /**
     * only proceed if service url is present
     * if agency name was passed to / or /login /signup paths,
     * serviceURL will be available after the agency config is fetched
     */

    useEffect(() => {
        if (!serviceUrl || !agency) {
            navigate("/login");
        }
    }, [serviceUrl, agency, navigate]);

    return (
        <Container maxWidth="lg" sx={{ p: 1.5 }}>
            <Stack
                direction={{
                    sm: "column",
                    md: "row",
                }}
                sx={{
                    alignItems: "center",
                    justifyContent: {
                        xs: "center",
                        md: "space-between",
                    },
                    my: 4,
                }}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <PersonAdd fontSize="large" />
                    <Typography variant="h4" component="h1">
                        Connect Signup
                    </Typography>
                </Stack>

                <Typography my={2}>
                    Already have an account? <Link to="/login">Login</Link>
                </Typography>
            </Stack>

            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                }}
                validationSchema={signupSchema}
                onSubmit={handleSignup}
            >
                {({ errors }) => (
                    <Form>
                        <Stack spacing={4}>
                            {/* Agency Profile */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Account Details
                                    </FormLabel>

                                    <Stack
                                        spacing={2}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <FormInput
                                            name="firstName"
                                            label="First Name"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            name="lastName"
                                            label="Last Name"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>

                                    <Stack
                                        spacing={2}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <PhoneNumberInput
                                            type="tel"
                                            name="phone"
                                            label="Phone"
                                            sx={{ flexGrow: 1 }}
                                            autoComplete="tel"
                                        />

                                        <FormDatePicker
                                            format="MM/dd/yyyy"
                                            name="birthdate"
                                            label="Birthday"
                                            sx={{ flexGrow: 1 }}
                                        />

                                        <FormInput
                                            type="email"
                                            name="email"
                                            label="Email"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Password */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Password
                                    </FormLabel>

                                    <Stack spacing={1}>
                                        <FormInput
                                            type="password"
                                            name="password"
                                            label="Password"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            type="password"
                                            name="confirmPassword"
                                            label="confirmPassword"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Accessibility */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Accessibility
                                    </FormLabel>

                                    <Stack
                                        spacing={1}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <FormCheckbox
                                            name="accessibility.hasPCA"
                                            label="I travel with a Personal Care Attendant"
                                            sx={{ flexGrow: 1 }}
                                        />

                                        <FormCheckbox
                                            name="accessibility.usesWheelchair"
                                            label="I would like an ADA accessible vehicle"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            name="accessibility.wheelChairType"
                                            label="Wheelchair Type"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Home Address */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Home Address
                                    </FormLabel>

                                    <Stack
                                        spacing={1}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <FormInput
                                            name="home.address1"
                                            label="Address"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            name="home.address2"
                                            label="Apt, Unit, Suite, etc"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>

                                    <Stack
                                        spacing={1}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <FormInput
                                            name="home.city"
                                            label="City"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            name="home.state"
                                            label="State"
                                            sx={{ flexGrow: 1 }}
                                        />

                                        <FormInput
                                            name="home.zip"
                                            label="Zip"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>
                        </Stack>

                        <Box maxWidth="sm" mx="auto" my={4}>
                            <LoadingButton
                                size="large"
                                fullWidth
                                type="submit"
                                variant="contained"
                                sx={{ py: 1.25 }}
                                loading={isLoading}
                                onClick={() => alertIfMissingFields(errors)}
                            >
                                Create account
                            </LoadingButton>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default Signup;
