import { v4 as uuidv4 } from "uuid";
import { utcOffset } from "src/utils/constants";
import { dateFromJson } from "src/utils/helpers";
import type {
    Client,
    CalendarRule,
    ParsedProgramCalendarRules,
} from "src/modules/auth/types";
import type {
    TripRequestFormValues,
    SelectionType,
    RequestedPlace,
} from "./types";
import { isObject, omit } from "lodash";
import { TimeTypes } from "src/types";
import { ParsedAddress } from "src/components/forms/types";
import moment from "moment";

export const OUT_OF_ZONE_ERROR = "Sorry address is not in active service area";
export const SAME_ADDRESS_ERROR =
    "That would be one very short trip. Please select a different drop-off location";
export const defaultTime = "0001-01-01";
export const omitFields = [
    "apptTime",
    "clientBirthDate",
    "pickUpTime",
    "dropOffTime",
    "returnTime",
    "pickUpPlace",
    "dropOffPlace",
];

export const omitTimeFields = ["date", "months", "years"];

interface AddressSuffix {
    suffix: string;
    key: keyof ParsedAddress;
}

export const addressFieldSufixes: AddressSuffix[] = [
    { suffix: "City", key: "city" },
    { suffix: "State", key: "state" },
    { suffix: "ZipCode", key: "zip" },
    { suffix: "PlaceName", key: "name" },
    { suffix: "Latitude", key: "latitude" },
    { suffix: "Longitude", key: "longitude" },
    { suffix: "Address", key: "address1" },
];

/**
 * How the rider is going to get back.
 * 0 = One way, 1 = Call for return, 2 = Specific return time requested
 * */
export const returnTripOptions = [
    { label: "No return trip needed", value: 0 },
    { label: "I'll call when I'm ready to be picked up", value: 1 },
    { label: "Please pick me up at:", value: 2 },
];

function getEquipment(accessibility: Client["accessibility"]) {
    if (!accessibility || !Array.isArray(accessibility.equipmentType))
        return "";

    const filtered = accessibility?.equipmentType?.filter((et) => {
        if (
            et.attributeValue ===
            accessibility.wheelChairType + " Wheelchair"
        ) {
            return false;
        }
        return true;
    });

    return filtered.map((item) => item.attributeValue).join();
}

export function getDayCalendar(
    date: Date,
    rules: ParsedProgramCalendarRules
): CalendarRule {
    const day = moment(date).format("dddd");
    let rule = {
        Active: false,
        StartJSON: new Date(),
        EndJSON: new Date(),
    };

    switch (day) {
        case "Sunday":
            rule = rules.Sunday;
            break;
        case "Monday":
            rule = rules.Monday;
            break;
        case "Tuesday":
            rule = rules.Tuesday;
            break;
        case "Wednesday":
            rule = rules.Wednesday;
            break;
        case "Thursday":
            rule = rules.Thursday;
            break;
        case "Friday":
            rule = rules.Friday;
            break;
        case "Saturday":
            rule = rules.Saturday;
            break;
        default:
            break;
    }
    return rule;
}

export function validateByCalendar(
    client: Client,
    date: Date,
    tripProgram: string,
    type: "date" | "time" = "date"
) {
    let error = "";
    const time = moment(date);

    /**
     * check if time or date is in the past
     * @see https://momentjs.com/docs/#/query/is-before/
     */

    if (time.isBefore(new Date(), type === "date" ? "day" : undefined)) {
        return `Please select a future ${type}`;
    } else if (time.isBefore(moment(new Date(), "HH:mm"))) {
        return "Please select a future time";
    }

    const activeProgram = client?.programs?.find(
        (item) => item.programName === tripProgram
    );

    if (!activeProgram || !activeProgram.programCalendarRules) return;
    const rules = JSON.parse(activeProgram.programCalendarRules);
    const dayCalendar = getDayCalendar(date, rules);

    // if program is not active for current day
    if (dayCalendar && !dayCalendar.Active) {
        if (type === "time") {
            error = `${activeProgram.programName} is not active at this time`;
        } else {
            const day = moment(date).format("dddd");
            error = `${activeProgram.programName} does not run on ${day}`;
        }
    }

    // if program is active, validate the period
    if (dayCalendar && dayCalendar.Active) {
        // extract time from dayCalendar and attach to current date for easier calculations
        const parsedStart = moment(dayCalendar.StartJSON).toObject();
        const startTime = moment(date).set(omit(parsedStart, omitTimeFields));

        const parsedEnd = moment(dayCalendar.EndJSON).toObject();
        const endTime = moment(date).set(omit(parsedEnd, omitTimeFields));

        const isBetween = time.isAfter(startTime) && time.isBefore(endTime);

        if (!isBetween) {
            error = `${activeProgram.programName} runs between ${moment(
                startTime
            ).format("hh:mm A")} and ${moment(endTime).format("hh:mm A")}`;
        }
    }

    return error;
}

// load the best program for the current time and day
export async function initTripProgram(client: Client) {
    // initialize program name from defaultProgram name
    let programName = client?.defaultProgramName ?? "";
    const apptTime = moment().add(15, "m").toDate();

    // if defaultProgram is not set, assign from the first program
    if (!programName && client.programs?.length) {
        programName = client.programs[0]?.programName;
    }

    for (const program of client?.programs) {
        const dateError = await validateByCalendar(
            client,
            apptTime,
            program.programName
        );

        const timeError = await validateByCalendar(
            client,
            apptTime,
            program.programName,
            "time"
        );

        // if program date and time are ok
        if (!dateError && !timeError) {
            programName = program.programName;
            break;
        }
    }

    return programName;
}

export async function initTripRequest(
    defaultClient: Client,
    requesterEmail: string = "",
    allPULocations = true
): Promise<TripRequestFormValues | any> {
    if (!Object.keys(defaultClient).length) return {};

    const client = { ...defaultClient };

    if (isObject(client.name)) {
        client.name = `${(client as any).name.first} ${
            (client as any).name.middle
        } ${(client as any).name.last}`;
    }

    if (
        (client as any)?.addresses &&
        Array.isArray((client as any).addresses)
    ) {
        client.home = (client as any)?.addresses[0];
    }

    const [clientFirstName, ...clientLastName] = client?.name?.split(" ");

    const apptTime = moment().add(15, "m");
    const defaultProgram = await initTripProgram(client);

    const fullAddress = `${client.home.address1}, ${client.home.city}, ${client.home.state}`;

    return {
        clientID: client.id, // we use this in tripPrograms to filter the programs by clientID if user is manager
        importTripID: uuidv4(),
        importClientID: uuidv4(),
        utcOffset: utcOffset,
        clientFirstName,
        clientLastName: clientLastName.join(" "),
        clientPhone: client.phone,
        clientEmail: client.email,
        pickUpPlace: {
            description: allPULocations ? fullAddress : "",
            name: allPULocations ? "Home" : "",
            address1: allPULocations ? client.home.address1 : "",
            city: allPULocations ? client.home.city : "",
            state: allPULocations ? client.home.state : "",
            zip: allPULocations ? client.home.zip : "",
            latitude: allPULocations ? client.home.lat || 0 : 0,
            longitude: allPULocations ? client.home.lng || 0 : 0,
        },
        pickUpAddress2: allPULocations ? client.home.address2 : "",
        dropOffPlace: {
            address1: "",
            city: "",
            state: "",
            zip: "",
            latitude: 0,
            longitude: 0,
            name: "",
            description: "",
        },
        dropOffAddress2: "",
        timeType: TimeTypes.pickedUp,
        apptTime: apptTime.toDate(),
        pickUpTime: apptTime.toDate(),
        dropOffTime: apptTime.toDate(),
        returnTime: apptTime.add(135, "m").toDate(),
        returnType: 0,
        clientInWC: client.accessibility.usesWheelchair,
        clientNeedsWCVan: client.accessibility.needsWheelchairVan,
        clientWCType: client.accessibility.wheelChairType ?? "", // possibly nullish
        clientIsDisabled: client.accessibility.isPersonWithDisability,
        clientDisabilities: client.specialNeeds,
        clientEquipment: getEquipment(client.accessibility),
        personalCareAttendant: client.accessibility.hasPCA,
        otherRiders: 0,
        carSeatDescription: "",
        clientBirthdate: client.birthDateJson
            ? dateFromJson(client.birthDateJson)
            : "",
        clientComments: client.notes,
        tripProgram: defaultProgram,
        tripComments: "",
        tripSource: "Downtown",
        tripUrl: "", //`will be filled on trip submit,
        newPassword: "",
        clientRESTUrl: "", // will be filled on trip submit with auth.user.RESTUrl,
        requesterEmail: requesterEmail, // will be filled on trip submit with auth.client.email,
        recurrance: {
            weekly: 1,
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
        },
    };
}

export function focusElement(id: string) {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
        });
        element.focus();
    }
}

/**
 *
 * @param type string SelectionType
 * @param parsedAddress (ParsedAddress)
 * @returns formatted address object with type prefix
 * @see TripRequestFormValues
 */
export function formatAddressFields(
    type: SelectionType = "pickUp",
    place: RequestedPlace
) {
    const parsed: any = {};
    // update sub field values first without rerendering
    for (const item of addressFieldSufixes) {
        const field = `${type}${item.suffix}`;
        const value = place[item.key as keyof RequestedPlace];
        parsed[field] = value;
    }
    parsed[`${type}AddressFull`] = place.description;

    return parsed;
}
