import { isFulfilled, isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { authActions } from "src/modules/auth/redux/authSlice";

/**
 * listen for expired token errors and refresh the token
 */
export const TokenExpiryCheckerMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

        // check if token exists and is valid for each request
        if (
            isFulfilled(action) &&
            action.payload &&
            typeof action.payload === "object"
        ) {
            /*
             * Update state in authSlice. We read these values in the
             * DashboardLayout component and handle token refresh
             */
            if (
                Object.hasOwn(action.payload, "tokenExists") &&
                Object.hasOwn(action.payload, "tokenIsValid")
            ) {
                const { tokenExists, tokenIsValid } = action.payload;
                api.dispatch(authActions.save({ tokenIsValid, tokenExists }));
            }
        }

        // if the request failed

        if (
            isRejectedWithValue(action) &&
            action.payload.data &&
            typeof action.payload.data === "object"
        ) {
            if (Object.hasOwn(action.payload.data, "tokenIsValid")) {
                const { endpointName } = action.meta.arg;
                const { tokenIsValid } = action.payload.data;

                /*
                 * Most requests will return tokenIsValid false if token is expired
                 * We also want to make sure the user is/was logged in already
                 */

                if (
                    !tokenIsValid &&
                    !["login", "signup"].includes(endpointName)
                ) {
                    enqueueSnackbar("Sorry your session expired", {
                        variant: "info",
                    });
                }
                /*
                 * Update state in authSlice. We read these values in the
                 * DashboardLayout component and handle token refresh
                 */
                api.dispatch(authActions.save({ tokenIsValid }));
            }
        }

        return next(action);
    };
