import { useField } from "formik";
import {
    Checkbox,
    FormControl,
    FormHelperText,
    FormControlLabel,
    FormControlProps,
} from "@mui/material";

interface Props extends Omit<FormControlProps, "size"> {
    name: string;
    label: string;
    helperText?: string;
    size?: number;
}

const FormCheckbox = ({
    id,
    name,
    label,
    helperText,
    size = 28,
    ...rest
}: Props) => {
    const [field, meta, helpers] = useField(name);
    return (
        <FormControl {...rest} error={meta.error ? true : false}>
            <FormControlLabel
                key={name}
                label={label}
                value={field.value}
                control={
                    <Checkbox
                        id={id}
                        checked={field.value}
                        onChange={(evt) => {
                            helpers.setValue(evt.target.checked);
                        }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: size } }}
                    />
                }
            />
            <FormHelperText>{meta.error || helperText}</FormHelperText>
        </FormControl>
    );
};

export default FormCheckbox;
