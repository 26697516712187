import * as CryptoJS from "crypto-js";
import * as shajs from "sha.js";
import {
    device,
    utcOffset,
    navigatorAPIUrl,
    paraPlanApiUrl,
} from "src/utils/constants";

import type { Credentials } from "./types";
import { PassioService } from "src/types";

function hashPasswordByService(password: string, service: PassioService) {
    let hashedPass = "";
    if (service === PassioService.navigator) {
        const key = "djdance";
        let hash = CryptoJS.HmacSHA256(password, key);
        hashedPass = hash.toString(CryptoJS.enc.Hex).toUpperCase();
    } else {
        hashedPass = new shajs.sha512()
            .update(password)
            .digest("hex")
            .toUpperCase();
    }

    return hashedPass;
}

export function generateLoginQuery(
    creds: Credentials,
    service = PassioService.paraplan,
    resume = false
) {
    let url = "";
    let hashedPass = resume
        ? creds.password
        : hashPasswordByService(creds.password, service);
    const hashedEmail = resume ? creds.email : encodeURIComponent(creds.email);

    if (service === PassioService.navigator) {
        url = `${navigatorAPIUrl}/Connect/login?email=${hashedEmail}&password=${hashedPass}`;
    } else {
        url = `${paraPlanApiUrl}/UserService/Login?UserName=${hashedEmail}&Password=${hashedPass}&Device=${device}&Version=0.1&UTCOffset=${utcOffset}`;
    }
    return { url, hashedEmail, hashedPass };
}
