import { DesktopDatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { FieldValidator, useField } from "formik";

interface Props extends DatePickerProps<any> {
    name: string;
    label: string;
    helperText?: string;
    onChange?: (value: Date) => void;
    validate?: FieldValidator;
}

const FormDatePicker = ({
    name,
    label,
    helperText,
    onChange,
    validate,
    ...rest
}: Props) => {
    const [field, meta, helpers] = useField({ name, validate });
    return (
        <DesktopDatePicker
            {...rest}
            {...field}
            label={label}
            onChange={(value) => {
                helpers.setValue(value);
                if (onChange) {
                    onChange(value);
                }
            }}
            onError={() => {
                helpers.setError("Please select a date");
            }}
            slotProps={{
                textField: {
                    error: meta.error ? true : false,
                    helperText: meta.error || helperText,
                    variant: "standard",
                },
            }}
        />
    );
};

export default FormDatePicker;
