import { useState, Fragment } from "react";
import {
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Collapse,
    Typography,
    Divider,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { startCase, isObject } from "lodash";
import { CalendarRule, Program } from "src/modules/auth/types";
import moment from "moment";

interface Props {
    program: Program | undefined;
}
const ProgramDetails = ({ program }: Props) => {
    const [showRules, setShowRules] = useState(true);

    function toggleColapse() {
        setShowRules(!showRules);
    }

    return (
        <List>
            <ListItem disableGutters>
                <ListItemText
                    primary="Description"
                    secondary={program?.programDescription || "N/A"}
                />
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primary="Archived"
                    secondary={program?.archived ? "Yes" : "No"}
                />
            </ListItem>
            <Divider />
            <ListItem disableGutters>
                <ListItemText
                    primary="Membership"
                    secondary={program?.membershipID || "N/A"}
                />
            </ListItem>
            <Divider />
            <ListItem
                disableGutters
                sx={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                }}
            >
                <ListItemText primary="Fixed Fee" sx={{ width: "40%" }} />

                <ListItemText sx={{ width: "60%" }}>
                    {program &&
                        Object.keys(program?.fixedFee)?.map((item, index) => (
                            <Typography key={index}>
                                <span>{startCase(item)}</span>:{" "}
                                <strong>
                                    {program?.fixedFee?.[item] || "N/A"}
                                </strong>
                            </Typography>
                        ))}
                </ListItemText>
            </ListItem>
            <Divider />

            <ListItem
                disableGutters
                sx={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                }}
            >
                <ListItemText primary="Per mile Fee" sx={{ width: "40%" }} />

                <ListItemText sx={{ width: "60%" }}>
                    {program &&
                        Object.keys(program?.perMileFee)?.map((item, index) => (
                            <Typography key={index}>
                                <span>{startCase(item)}</span>:{" "}
                                <strong>
                                    {program?.perMileFee?.[item] || "N/A"}
                                </strong>
                            </Typography>
                        ))}
                </ListItemText>
            </ListItem>
            <Divider />

            <ListItemButton onClick={toggleColapse} disableGutters>
                <ListItemText primary="Program Calendar Rules" />

                {showRules ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={showRules} timeout="auto" unmountOnExit>
                <List>
                    {program &&
                        isObject(program.parsedProgramCalendarRules) &&
                        Object.keys(program?.parsedProgramCalendarRules)?.map(
                            (item, index) => {
                                const rule: CalendarRule | string = isObject(
                                    program?.parsedProgramCalendarRules
                                )
                                    ? program?.parsedProgramCalendarRules?.[
                                          item
                                      ]
                                    : item;

                                return (
                                    <ListItem
                                        key={index}
                                        disableGutters
                                        divider
                                        sx={{
                                            alignItems: "flex-start",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <ListItemText
                                            sx={{ width: "40%" }}
                                            primary={startCase(item)}
                                        />
                                        <ListItemText sx={{ width: "60%" }}>
                                            {isObject(rule) ? (
                                                <Fragment>
                                                    <Typography>
                                                        <span>Active</span>:{" "}
                                                        <strong>
                                                            {rule?.Active
                                                                ? "Yes"
                                                                : "No"}
                                                        </strong>
                                                    </Typography>

                                                    <Typography>
                                                        <span>Start</span>:{" "}
                                                        <strong>
                                                            {moment(
                                                                rule?.StartJSON
                                                            ).format("LLLL")}
                                                        </strong>
                                                    </Typography>

                                                    <Typography>
                                                        <span>End</span>:{" "}
                                                        <strong>
                                                            {moment(
                                                                rule?.EndJSON
                                                            ).format("LLLL")}
                                                        </strong>
                                                    </Typography>
                                                </Fragment>
                                            ) : (
                                                rule
                                            )}
                                        </ListItemText>
                                    </ListItem>
                                );
                            }
                        )}
                </List>
            </Collapse>
        </List>
    );
};

export default ProgramDetails;
