import { Box, Button, Avatar, Typography, Container } from "@mui/material";
import { useNavigate, useRouteError } from "react-router-dom";
import logoImg from "src/images/logo-full-green.png";

const NotFound = () => {
    const navigate = useNavigate();
    const error = useRouteError();
    console.error(error);

    function handleNavigate() {
        if (error) {
            navigate("/login");
        } else {
            navigate(-1);
        }
    }

    return (
        <Container
            component="main"
            sx={{
                display: "grid",
                placeItems: "center",
                height: "100vh",
                textAlign: "center",
            }}
        >
            <Box
                sx={{
                    marginTop: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar
                    alt="logo"
                    src={logoImg}
                    variant="square"
                    sx={{
                        my: 4,
                        mx: "auto",
                        height: 100,
                        width: 300,
                        objectFit: "",
                    }}
                />

                <Typography component="p" variant="h5" my={2}>
                    Sorry this page is unavailable
                </Typography>

                <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, py: 1.5, color: "white" }}
                    onClick={handleNavigate}
                >
                    Go Back
                </Button>
            </Box>
        </Container>
    );
};

export default NotFound;
