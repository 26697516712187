import { createSlice, createAction } from "@reduxjs/toolkit";
import type { RootState } from "src/redux";
import type {
    User,
    Client,
    Config,
    Geozone,
    Place,
    ConfigResponse,
    GeozoneResponse,
    PlacesResponse,
    ManagedClient,
    GetClientsResponse,
    changePasswordRequest,
    URLMapperResponse,
    GetAgencyRequest,
    CacheResetRequest,
    RefreshTokenRequest,
    Program,
} from "../types";
import API from "src/redux/api";
import {
    device,
    navigatorAPIUrl,
    paraPlanApiUrl,
    utcOffset,
} from "src/utils/constants";
import { filterFundingSources } from "src/utils/helpers";
import { ListResponse, PassioService } from "src/types";

// Define a type for the slice state
interface AuthState {
    user: User;
    token: string;
    passioToken: string;
    serviceUrl: string;
    service: PassioService;
    autoLogin?: boolean;
    savedLogin?: {
        email: string;
        password: string;
    };
    geozones: Geozone[];
    client: Client;
    clients: ManagedClient[];
    config: Config;
    places: Place[];
    programs: Program[];
    availableFundingSources: string[];
    isManager: boolean;
    tokenExists: boolean;
    tokenIsValid: boolean;
}

// Define the initial state using that type
const initialState: AuthState = {
    user: {} as User,
    token: "",
    tokenExists: false,
    tokenIsValid: false,
    passioToken: "",
    service: PassioService.paraplan,
    serviceUrl: "",
    geozones: [],
    client: {} as Client,
    clients: [],
    config: {} as Config,
    places: [],
    programs: [],
    availableFundingSources: [],
    isManager: false,
};

export const authAPI = API.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<User, string>({
            query: (url) => ({
                url,
                // Example: we have a backend API always returns a 200,
                // but sets an `errorMessage` property when there is an error.
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        getClient: build.query<Client, number | string>({
            query: (id) => ({
                url: `ClientService/Client/${id}`,
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        getClients: build.query<GetClientsResponse, boolean>({
            query: (lightWeight) => ({
                url: "ClientService/Client/ProgramParticipants/",
                params: {
                    Lightweight: lightWeight,
                },
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        getPrograms: build.query<ListResponse<Program>, void>({
            query: () => ({
                url: `ProgramService/Programs`,
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        getGeoZones: build.query<GeozoneResponse, void>({
            query: () => ({
                url: "ProgramService/Geozones",
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        getPassioToken: build.query<string, void>({
            query: () => ({
                url: `${navigatorAPIUrl}/Internal/mynameIsMud311`,
                responseHandler: "text",
            }),
        }),
        getConfig: build.query<ConfigResponse, void>({
            query: () => ({
                url: "UserService/Config",
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        getAgencyConfig: build.query<ConfigResponse, GetAgencyRequest>({
            query: (data) => ({
                url: `${data.url}UserService/ConfigLite`, // request doesn't require a token so we build directly to prevent blocking
                params: { Agency: data.agency },
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
            transformResponse: (response: ConfigResponse, meta, arg) => {
                return {
                    ...response,
                    entity: { ...response.entity, agencyId: arg.agency },
                };
            },
        }),
        getUrlMapper: build.query<URLMapperResponse, string>({
            query: (agency) => ({
                url: `${paraPlanApiUrl}/UserService/URLMapper`, // request doesn't require a token so we build directly to prevent blocking
                params: {
                    Agency: agency,
                },
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        getPlaces: build.query<PlacesResponse, void>({
            query: () => ({
                url: "PlaceService/OnDemand",
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        /**
         * pass id as new to to url and 0 in body to create a new client record
         * Used to create new riders
         */
        updateProfile: build.mutation<Client, any>({
            query: (data) => ({
                url: `ClientService/Client/${data.id}`,
                method: "post",
                body: {
                    ...data,
                    id: data.id === "new" ? 0 : data.id,
                },
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        changePassword: build.mutation<any, changePasswordRequest>({
            query: (data) => ({
                url: `UserService/ChangePassword`,
                method: "post",
                body: data,
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        resetCache: build.mutation<any, CacheResetRequest>({
            query: (data) => ({
                url: `ProgramService/ResetCache/`,
                params: {
                    Geozones: data.geozones,
                    Filespecs: data.filespecs,
                },
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        // TODO: Define req, res types
        signup: build.mutation<any, any>({
            query: ({ serviceUrl, agency, data }) => ({
                // service url is always suffixed with a /
                url: `${serviceUrl}UserService/Register`,
                method: "post",
                params: {
                    Token: data.password,
                    Device: device,
                    UTCOffset: utcOffset,
                    Agency: agency,
                },
                body: data,
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        refreshToken: build.mutation<any, RefreshTokenRequest>({
            query: (data) => ({
                url: `/UserService/RefreshToken`,
                params: {
                    UserName: data.email,
                    Password: data.password,
                },
                validateStatus: (response, result) =>
                    response.status === 200 && result,
            }),
            transformResponse: (response: string) => response?.replace('"', ""), // remove quotes
        }),
    }),
    overrideExisting: false,
});

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        save: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        clear: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authAPI.endpoints.login.matchFulfilled,
            (state, { payload }) => {
                // This endpoint is renewal we want to make sure current sources are not overiden
                const sources = payload.AvailableFundingSources?.length
                    ? [
                          ...state.availableFundingSources,
                          ...payload.AvailableFundingSources,
                      ]
                    : state.availableFundingSources;
                return {
                    ...state,
                    user: payload,
                    token: payload.Key,
                    tokenIsValid: payload.tokenIsValid,
                    serviceUrl: payload.RESTUrl,
                    availableFundingSources: sources,
                    isManager: payload.UserType === 1,
                };
            }
        );
        builder.addMatcher(
            authAPI.endpoints.getGeoZones.matchFulfilled,
            (state, { payload }) => {
                state.geozones = payload.list;
            }
        );
        builder.addMatcher(
            authAPI.endpoints.getClient.matchFulfilled,
            (state, { payload }) => {
                state.client = payload;
                // filter available funding sources if client is a mobilitiy manager
                if (state.user.UserType === 2) {
                    const sources = filterFundingSources(payload?.programs);
                    state.availableFundingSources = sources;
                }
            }
        );
        builder.addMatcher(
            authAPI.endpoints.getClients.matchFulfilled,
            (state, { payload }) => {
                state.clients = payload.list;
            }
        );
        builder.addMatcher(
            authAPI.endpoints.getPrograms.matchFulfilled,
            (state, { payload }) => {
                state.programs = payload.list;
            }
        );
        builder.addMatcher(
            authAPI.endpoints.getPassioToken.matchFulfilled,
            (state, { payload }) => {
                state.passioToken = payload;
            }
        );
        builder.addMatcher(
            authAPI.endpoints.getConfig.matchFulfilled,
            (state, { payload }) => {
                state.config = payload.entity;
            }
        );
        builder.addMatcher(
            authAPI.endpoints.getAgencyConfig.matchFulfilled,
            (state, { payload }) => {
                state.config = payload.entity;
            }
        );
        builder.addMatcher(
            authAPI.endpoints.getPlaces.matchFulfilled,
            (state, { payload }) => {
                state.places = payload.list;
            }
        );
        builder.addMatcher(
            authAPI.endpoints.getUrlMapper.matchFulfilled,
            (state, { payload }) => {
                state.serviceUrl = payload.entity;
            }
        );
        builder.addMatcher(
            authAPI.endpoints.refreshToken.matchFulfilled,
            (state, { payload }) => {
                state.token = payload;
                state.tokenIsValid = true;
            }
        );
    },
});

export const authActions = authSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const authSelector = (state: RootState) => state.auth;
export const userSelector = (state: RootState) => state.auth.user;

export const logout = createAction("LOG_OUT");

export default authSlice.reducer;
