import { Libraries } from "@react-google-maps/api";
import momenttz from "moment-timezone";

export const utcOffset = momenttz.tz(momenttz.tz.guess()).utcOffset() / 60;

export const device =
    "downtownv2" +
    (process.env.NODE_ENV === "production" ? "" : `-${process.env.NODE_ENV}`);

export const version = "2023.4.3";

export const loginCookieLifetime = 60 * 60 * 20;

export const colors = {
    primary: "#2D9EAF", // PassioPrimaryColor
    secondary: "#E26B43", // PassioSecondaryColor
};

export const navigatorAPIUrl = process.env.REACT_APP_NAVIGATOR_API_URL;
export const paraPlanApiUrl = process.env.REACT_APP_PARAPLAN_API_URL;
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const googleMapLibraries: Libraries = ["maps", "places"];
