// trip detail helpers

import moment from "moment";
import { capitalize, startCase } from "lodash";

export function getStatusText(rideStatus: number | undefined) {
    switch (rideStatus) {
        case 1:
            return "Waiting for approval";
        case 2:
            return "Approved. Waiting for assignment";
        case 3:
        case 4:
            return "Assigned to driver";
        case 5:
            return "Driver on their way";
        case 6:
            return "Driver is almost there";
        case 7:
            return "Driver is at pick up location";
        case 8:
            return "Heading to drop off location";
        case 9:
            return "Driver is almost at drop off location";
        case 10:
            return "Driver is at drop off location";
        case 11:
            return "Trip has been completed";
        case 12:
            return "Trip has been rejected";
        case 13:
            return "Trip has been cancelled";
        default:
            return "Email confirmation is on the way!";
    }
}

export function formatAddress(address: any) {
    if (!address && !address?.name && !address?.city) {
        return "N/A";
    }
    return `${address.name}, ${address.city}`;
}

export function formatTime(time: any) {
    if (!time) return "N/A";
    return moment.unix(parseInt(time)).format("LLLL");
}

export function formatName(request: any) {
    if (!request && !request?.clientFirstName && !request?.clientLastName)
        return "";
    return `${capitalize(request.clientFirstName)} ${startCase(
        capitalize(request.clientLastName)
    )}`;
}
