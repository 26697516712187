import {
    Box,
    Grid,
    TextField,
    Typography,
    Autocomplete,
    AutocompleteProps,
    createFilterOptions,
    AutocompleteChangeReason,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import type { Place } from "src/modules/auth/types";
import type { ParsedAddress } from "./types";
import { isEqual, isObject, pick } from "lodash";
import { useField, FieldValidator } from "formik";
import { defaultValues } from "./helpers";
import { getProperty } from "dot-prop";

interface Props
    extends Omit<
        AutocompleteProps<any, any, any, any>,
        "renderInput" | "options" | "onChange"
    > {
    name: string;
    label: string;
    helperText?: string;
    options: Place[];
    onChange?: (value: ParsedAddress | undefined) => void;
    validate?: FieldValidator;
}

const filter = createFilterOptions();
const filterOptions = (options: any, params: any): any => {
    return filter(options, params);
};

const selectedFields = ["name", "address1", "address2", "city", "state", "zip"];

const PlacePicker = ({
    value,
    name,
    label,
    onChange,
    validate,
    helperText,
    options = [],
    ...rest
}: Props) => {
    const [field, meta, helpers] = useField({ name, validate });

    function handleChange(
        _: any,
        value: Place,
        reason: AutocompleteChangeReason
    ) {
        if (reason === "clear") {
            helpers.setValue(defaultValues);
            if (onChange) onChange(defaultValues);
            return;
        }

        const parsedAddress: any = {
            ...pick(value, selectedFields),
            street: "",
            latitude: value?.lat ?? 0,
            longitude: value?.lng ?? 0,
            description: `${value?.name} ${value?.address1}`,
        };

        helpers.setValue(parsedAddress);
        if (onChange) onChange(parsedAddress);
    }

    function compareAddresses(place: Place) {
        return isEqual(place.name, place.address1);
    }

    function getOptionLabel(option: Place) {
        let label = "";
        if (isObject(option) && Object.keys(option).length) {
            if (compareAddresses(option)) {
                label = option.name;
            } else {
                label = `${option.name} ${option.address1}`;
            }
        }
        return label;
    }

    function prepareSuggestions(option: Place, inputValue: string) {
        const isSameAddress = compareAddresses(option);

        let matches = [];
        let parts = [];

        if (isSameAddress) {
            matches = match(option.name, inputValue);
            parts = parse(option.name, matches);
        } else {
            const address = `${option.name} ${option.address1}`;
            matches = match(address, inputValue);
            parts = parse(address, matches);
        }

        return parts;
    }

    return (
        <Autocomplete
            {...rest}
            options={options}
            value={field.value}
            onChange={handleChange}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label={label}
                    error={meta.error ? true : false}
                    helperText={
                        getProperty(meta.error, "description") || helperText
                    }
                    variant="standard"
                />
            )}
            renderOption={(props, option, { inputValue }) => {
                const parts = prepareSuggestions(option, inputValue);
                return (
                    <li {...props}>
                        <Grid container alignItems="center" p={1}>
                            <Grid item sx={{ display: "flex", width: 44 }}>
                                <LocationOnIcon
                                    sx={{ color: "text.secondary" }}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    width: "calc(100% - 44px)",
                                    wordWrap: "break-word",
                                }}
                            >
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{
                                            fontWeight: part.highlight
                                                ? "bold"
                                                : "regular",
                                        }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                ></Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};

export default PlacePicker;
