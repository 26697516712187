import { memo, useMemo, useState, useCallback } from "react";
import {
    MarkerF,
    PolygonF,
    CircleF,
    GoogleMapProps,
    useJsApiLoader,
    GoogleMap as BaseGoogleMap,
} from "@react-google-maps/api";
import { Box, SxProps, Typography } from "@mui/material";
import { googleMapsApiKey, googleMapLibraries } from "src/utils/constants";
import useDirections from "./useDirections";
import Splashscreen from "../Splashscreen";

interface CircleData {
    options: google.maps.CircleOptions;
    center: google.maps.LatLngLiteral;
}

interface PolygonData {
    options: google.maps.PolygonOptions;
    paths: google.maps.LatLngLiteral[];
}

export interface MapGeoFence extends Record<string, any> {
    polygons: PolygonData[];
    circles: CircleData[];
}

interface Props extends GoogleMapProps {
    sx?: SxProps<any>;
    directions?: google.maps.DirectionsResult | undefined;
    showDirections?: boolean;
    pickUpCoords?: google.maps.LatLngLiteral;
    dropOffCoords?: google.maps.LatLngLiteral;
    vehicleCoords?: google.maps.LatLngLiteral;
    riderCoords?: google.maps.LatLngLiteral;
    geoFence?: MapGeoFence;
    loading?: boolean;
}

const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
};

const GoogleMap = ({
    zoom = 15,
    sx = containerStyle,
    center,
    pickUpCoords,
    dropOffCoords,
    vehicleCoords,
    riderCoords,
    geoFence,
    loading = false,
    showDirections = true,
}: Props) => {
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const { routeMeta, directions } = useDirections({
        map,
        pickUpCoords,
        dropOffCoords,
        vehicleCoords,
        riderCoords,
    });

    // this uses @googlemaps/js-api-loader under the hood
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: googleMapsApiKey ?? "",
        libraries: googleMapLibraries,
    });

    const calculatedCenter = useMemo(() => {
        // adjust center
        let defaultCenter = undefined;
        if (center) {
            defaultCenter = center;
        } else if (pickUpCoords && pickUpCoords.lat && pickUpCoords.lng) {
            defaultCenter = pickUpCoords;
        } else if (dropOffCoords && dropOffCoords.lat && dropOffCoords.lng) {
            defaultCenter = dropOffCoords;
        }

        return defaultCenter;
    }, [pickUpCoords, dropOffCoords, center]);

    const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);
    const onUnmount = useCallback((map: google.maps.Map) => setMap(null), []);

    // show custom loader
    if (!isLoaded || loading) return <Splashscreen sx={sx} />;

    return (
        <Box sx={{ ...sx, borderRadius: 3 }}>
            <BaseGoogleMap
                mapContainerStyle={containerStyle}
                onLoad={onLoad}
                onUnmount={onUnmount}
                center={calculatedCenter}
                zoom={zoom}
            >
                {/* Child components, such as markers, info windows, etc. */}

                {/* Marker for pick up location */}
                {pickUpCoords &&
                    pickUpCoords.lat !== 0 &&
                    pickUpCoords.lng !== 0 && (
                        <MarkerF
                            position={pickUpCoords}
                            options={{ map: map, title: "Pick Up" }}
                        />
                    )}

                {/* Marker for drop off location, only plot when theres not direction to avoid overlap*/}
                {dropOffCoords &&
                    dropOffCoords.lat !== 0 &&
                    dropOffCoords.lng !== 0 &&
                    !directions?.routes && (
                        <MarkerF
                            position={dropOffCoords}
                            options={{ map: map, title: "Drop Off" }}
                        />
                    )}

                {/* Marker for pick up location */}
                {vehicleCoords && vehicleCoords.lat && vehicleCoords.lng && (
                    <MarkerF
                        position={vehicleCoords}
                        options={{ map: map, title: "Vehicle", label: "V" }}
                    />
                )}
                {/* Marker for rider location only plot if rider is not on board */}
                {riderCoords &&
                    riderCoords.lat !== 0 &&
                    riderCoords.lng !== 0 && // eslint-disable-next-line eqeqeq
                    riderCoords != vehicleCoords && (
                        <MarkerF
                            position={riderCoords}
                            options={{
                                map: map,
                                title: "Rider",
                                label: "R",
                            }}
                        />
                    )}

                {/* Geofence, polygons and circles */}
                {geoFence &&
                    geoFence.polygons.length > 0 &&
                    geoFence.polygons.map((item: any, index: number) => (
                        <PolygonF
                            key={index}
                            path={item.paths}
                            options={item.options}
                        />
                    ))}

                {geoFence &&
                    geoFence.circles.length > 0 &&
                    geoFence.circles.map((item: any, index: number) => (
                        <CircleF
                            key={index}
                            center={item.center}
                            options={item.options}
                        />
                    ))}
            </BaseGoogleMap>

            {/* Show details */}
            {routeMeta.distance && routeMeta.duration && showDirections && (
                <Typography align="center" p={1}>
                    Distance: {routeMeta.distance} &nbsp;&nbsp; Duration:{" "}
                    {routeMeta.duration}
                </Typography>
            )}
        </Box>
    );
};

export default memo(GoogleMap);
