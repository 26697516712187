import React from "react";

import {
    Box,
    Slide,
    Stack,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Dialog,
    Grid,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Close } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { useAppSelector, useSelectedClient } from "src/hooks";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { FormInput } from "src/components";
import GoogleMap, { MapGeoFence } from "src/components/map/GoogleMap";

import ProgramDetails from "./ProgramDetails";

interface Props {
    label?: string;
    name?: string;
    geoFence?: MapGeoFence; // passed down from location selector
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    loading?: boolean;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TripPrograms = ({
    label = "Service",
    name = "tripProgram",
    onChange,
    geoFence,
    loading,
}: Props) => {
    const [open, setOpen] = React.useState(false);
    const { values } = useFormikContext<any>();
    const { isManager, availableFundingSources } = useAppSelector(authSelector);

    // since managers can request trips for a rider, we want to make sure we are loading
    // the riders programs instead of the managers at this point
    const client = useSelectedClient(values.clientID);

    const program = React.useMemo(() => {
        let item = client?.programs?.find(
            (program) => program.programName === values.tripProgram
        );

        // programCalendarRules is a JSON string so we have to parse it
        if (item && typeof item.programCalendarRules === "string") {
            return {
                ...item,
                parsedProgramCalendarRules: JSON.parse(
                    item.programCalendarRules
                ),
            };
        }

        return item;
    }, [client, values.tripProgram]);

    const filteredPrograms = React.useMemo(() => {
        if (client) {
            // only select programs which are not archived
            return client?.programs?.filter((program) => {
                return (
                    availableFundingSources?.includes(program.programName) &&
                    !program.archived
                );
            });
        } else {
            return [];
        }
    }, [client, availableFundingSources]);

    function handleClose() {
        setOpen(false);
    }

    const TripSelector = () => (
        <FormInput
            select
            fullWidth
            tabIndex={0}
            name={name}
            label={label}
            InputLabelProps={{
                shrink: true,
            }}
            SelectProps={{
                native: true,
            }}
            onChange={onChange}
        >
            {filteredPrograms && filteredPrograms?.length > 0
                ? filteredPrograms?.map((program) => (
                      <option
                          key={program.programName}
                          value={program.programName}
                      >
                          {program.programName}
                          {program.membershipID && `(${program.membershipID})`}
                      </option>
                  ))
                : availableFundingSources.map((program) => (
                      <option key={program} value={program}>
                          {program}
                      </option>
                  ))}
        </FormInput>
    );

    return (
        <React.Fragment>
            <Stack spacing={2} direction="row" tabIndex={0}>
                <Box flexGrow={2}>
                    <TripSelector />
                </Box>
                {!isManager && values.tripProgram !== "" && (
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => setOpen(true)}
                    >
                        View Details
                    </Button>
                )}
            </Stack>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar color="primary" sx={{ position: "relative" }}>
                    <Toolbar sx={{ justifyContent: "space-between" }}>
                        <Typography variant="h6" component="div">
                            Program Details
                        </Typography>
                        <IconButton
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Box p={{ xs: 2, md: 4 }}>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        mb={2}
                    >
                        <Typography variant="h4" component="h2" flexGrow={3}>
                            {program?.programName}
                            {program?.membershipID &&
                                ` (${program.membershipID})`}
                        </Typography>

                        <Box flexGrow={1}>
                            <TripSelector />
                        </Box>
                    </Stack>

                    <Grid container spacing={2}>
                        {/* Details  */}
                        <Grid item xs={12} md={6}>
                            <ProgramDetails program={program} />
                        </Grid>
                        {/* Map preview for program geozones */}
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justityContent: "center",
                            }}
                        >
                            <GoogleMap
                                loading={loading}
                                pickUpCoords={{
                                    lat: values.pickUpLatitude,
                                    lng: values.pickUpLongitude,
                                }}
                                dropOffCoords={{
                                    lat: values.dropOffLatitude,
                                    lng: values.dropOffLongitude,
                                }}
                                geoFence={geoFence}
                                sx={{
                                    minHeight: 450,
                                    height: "100%",
                                    width: "100%",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

export default TripPrograms;
