import { useCallback, useEffect, useState, useRef } from "react";
import { Box, Link, Container, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import { enqueueSnackbar } from "notistack";
import { authAPI, authActions, authSelector } from "../redux/authSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { FormInput, FormCheckbox, Splashscreen } from "src/components";
import { version, utcOffset } from "src/utils/constants";
import {
    getService,
    getServiceBrandName,
    isErrorWithMessage,
} from "src/utils/helpers";
import { useAppDispatch, useAppSelector } from "src/hooks";
import type { Credentials } from "../types";
import { generateLoginQuery } from "../helpers";
import { loginSchema } from "../schema";
import { useTitle } from "react-use";
import logoImg from "src/images/logo-full-green.png";

/**
 * @param agency: agency name
 * @param service paraplan | navigator
 * @example /login?agency=ENGRAPHTRANSIT&service=paraplan
 */

const Login = () => {
    useTitle("Login");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const service = getService();

    const [login, { isLoading }] = authAPI.useLoginMutation();
    const [processing, setProcessing] = useState({
        active: false,
        message: "processing",
    });

    const {
        config,
        autoLogin,
        savedLogin,
        service: savedService,
    } = useAppSelector(authSelector);
    const autoLoginChecked = useRef(false); // use a ref to track if autologin has been checked, prevents login loops
    const shouldAutoLogin =
        autoLogin && savedLogin && savedLogin?.email && savedLogin?.password;

    /*
     * @params resume - if user is resuming a session and has saved creds
     * @params values - user credentials could be cached or filled in form
     */
    const handleLogin = useCallback(
        async (values: Credentials, resume = false) => {
            try {
                const parsedService = resume ? savedService : service;
                const query = generateLoginQuery(values, parsedService, resume);
                const user = await login(query.url).unwrap(); // response is auto saved in state

                // Only proceed if user is authorized
                if (user.PPPAccess !== 1) {
                    throw new Error("Portal access is not available");
                }

                /**
                 * Preserve hashed credentials if autologin is enabled
                 * Save service name too
                 **/

                if (values.autoLogin) {
                    dispatch(
                        authActions.save({
                            service,
                            autoLogin: true,
                            savedLogin: {
                                email: query.hashedEmail,
                                password: query.hashedPass,
                            },
                        })
                    );
                } else {
                    dispatch(authActions.save({ service }));
                }

                // load passioToken, importantn for next steps
                setProcessing({
                    active: true,
                    message: "Authorizing Passio Access",
                });
                const passioToken = await dispatch(
                    authAPI.endpoints.getPassioToken.initiate()
                );

                if (passioToken.isError) {
                    throw new Error("Sorry we couldn't authorize your access");
                }

                // load geozones
                setProcessing({
                    active: true,
                    message: "Loading Geozones",
                });
                const geozones = await dispatch(
                    authAPI.endpoints.getGeoZones.initiate()
                );

                if (geozones.isError) {
                    throw new Error("Sorry we couldn't load your zones");
                }

                // if user is a manager, fetch their clients and do some manager stuff
                if (user.ClientID === 0 || user.UserType === 1) {
                    // load clients
                    setProcessing({
                        active: true,
                        message: "Loading all clients. This may take a moment",
                    });

                    if (!user.ProviderPrograms) {
                        throw new Error("Invalid mobility manager infomation");
                    }

                    if (
                        !user.ProviderCanViewClientDetails ||
                        !user.ProviderCanRequestTrips
                    ) {
                        throw new Error("Unauthorized");
                    }

                    setProcessing({
                        active: true,
                        message: "Loading Programs",
                    });
                    const programs = await dispatch(
                        authAPI.endpoints.getPrograms.initiate()
                    );

                    if (programs.isError) {
                        throw new Error("Sorry we couldn't load your programs");
                    }
                } else {
                    // otherwise, load the client's profile
                    setProcessing({
                        active: true,
                        message: "Setting up client",
                    });

                    const client = await dispatch(
                        authAPI.endpoints.getClient.initiate(user.ClientID)
                    );

                    if (client.isError) {
                        throw new Error("Sorry we couldn't load your profile");
                    }
                }

                // load user config
                setProcessing({
                    active: true,
                    message: "Loading preferences",
                });

                const config = await dispatch(
                    authAPI.endpoints.getConfig.initiate()
                );

                if (config.isError) {
                    throw new Error("Sorry we couldn't load your preferences");
                }

                // check location preferences
                const preferences = config.data?.entity?.ConnectPreferences;
                if (
                    !preferences?.CanRequestAnyPULocation ||
                    !preferences?.CanRequestAnyDOLocation
                ) {
                    // load places
                    setProcessing({
                        active: true,
                        message: "Loading Places",
                    });

                    const places = await dispatch(
                        authAPI.endpoints.getPlaces.initiate()
                    );

                    if (places.isError) {
                        throw new Error("Sorry we couldn't load places");
                    }
                }

                /*
                 * all state is filtered directly in redux state matchers after request complete
                 * check the api slices for each module to learn more
                 */

                enqueueSnackbar("Login successfull", {
                    variant: "success",
                });

                // if user was redirected to login page, redirect back to where they came from
                if (location.state?.from) {
                    navigate(location.state.from, { replace: true, state: {} });
                } else if (user.UserType === 1) {
                    // Redirect to riders list if user is a mobility manager
                    navigate("/riders");
                } else {
                    navigate("/trips");
                }

                setProcessing({
                    active: false,
                    message: "",
                });
            } catch (error) {
                if (isErrorWithMessage(error)) {
                    enqueueSnackbar(error.message, { variant: "error" });
                } else {
                    enqueueSnackbar(
                        "Login failed. Please check your password/PIN ",
                        {
                            variant: "error",
                        }
                    );
                }
                setProcessing({
                    active: false,
                    message: "",
                });
            }
        },
        [dispatch, location, login, navigate, service, savedService]
    );

    // handle autologin
    useEffect(() => {
        if (shouldAutoLogin && !autoLoginChecked.current) {
            handleLogin(
                {
                    ...savedLogin,
                    autoLogin,
                },
                true
            );
            setProcessing({
                active: true,
                message: "Auto login enabled, we are restoring your session",
            });
        }
        return () => {
            autoLoginChecked.current = true;
        };
    }, [shouldAutoLogin, autoLogin, savedLogin, handleLogin]);

    // show loader when processing
    if (processing.active) {
        return <Splashscreen description={processing.message} />;
    }

    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{
                display: "grid",
                placeItems: "center",
                height: "100vh",
            }}
        >
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box
                    component="img"
                    alt="logo"
                    src={logoImg}
                    sx={{
                        my: 4,
                        mx: "auto",
                        height: 100,
                        width: 275,
                        objectFit: "contain",
                    }}
                />

                <Typography
                    component="h1"
                    variant="h4"
                    fontWeight="bold"
                    gutterBottom
                >
                    Connect Rider
                </Typography>

                <Typography component="h2" variant="h5">
                    {config?.ConnectPreferences?.DisplayName}
                </Typography>

                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                        autoLogin: false,
                    }}
                    validationSchema={loginSchema}
                    onSubmit={(values) => handleLogin(values)}
                >
                    <Box component={Form} sx={{ mt: 1 }}>
                        <FormInput
                            fullWidth
                            name="email"
                            label="Email address"
                            margin="normal"
                        />
                        <FormInput
                            fullWidth
                            name="password"
                            label="Password or Pin"
                            type="password"
                            margin="normal"
                        />
                        <FormCheckbox label="Remember me" name="autoLogin" />
                        <LoadingButton
                            size="large"
                            type="submit"
                            fullWidth
                            variant="contained"
                            loading={isLoading}
                            sx={{ my: 2, py: 1, color: "primary.contrastText" }}
                        >
                            {isLoading ? "Verifying account" : "Sign In"}
                        </LoadingButton>
                    </Box>
                </Formik>

                {/* Signup is only available via agency */}
                {config?.AgencyName && (
                    <Typography align="center" my={2}>
                        Need a connect account?{" "}
                        <Link href="/signup">Sign up</Link>
                    </Typography>
                )}

                <Typography align="center" variant="body2">
                    {`${getServiceBrandName(service)} V ${
                        process.env.REACT_APP_VERSION
                    } ${version} UTC ${utcOffset}`}
                </Typography>
            </Box>

            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ mt: 8, mb: 4 }}
            >
                {"Copyright © "}
                <Link color="inherit" href="https://passiotech.com/">
                    Passio Technologies
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        </Container>
    );
};

export default Login;
